// Import Components
import PageTitle from '../../components/PageTitle'
// Component
const Caregiver  = () => {

  const pageTitle = 'Caregiver knowledge'

  return (
    <> 
      <PageTitle title = { pageTitle } />
      <div className="article_content col2">
        <p>In Balkan countries, early introduction of foods prior to 6 months of age, perceptions of insufficient breastmilk , and use of infant formula go hand-in-hand,  which not only affects exclusive breastfeeding but also timely introduction of complementary foods and associated feeding practices.</p>
      </div>
    </>
  )
}

export default Caregiver 
