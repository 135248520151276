import { Link } from "react-router-dom";

// Component
const PolicyMenuLink = ({ title, linkTo }) => {
  return (
      <Link
        to={ linkTo }
        className="
          py-1 mb-1 
          hover:text-sec_blue
          ani03s cursor-pointer
        " 
      >
        { title }
      </Link>
  ) 
}

export default PolicyMenuLink
