import { useState } from 'react'
// Import Components
import PageTitle from '../../components/PageTitle'
import Breadcrumbs from '../../components/Breadcrumbs'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
import CoverImg from '../../components/CoverImg'
// Import Content
import Table06 from '../../page_elements/tables/Table06'
import Table07 from '../../page_elements/tables/Table07'
// Import Image
import Image from '../../images/enabling.jpg'
// Component
const Enabling  = () => {

  const pageTitle = 'Enabling  environment'
  const table1Desc = 'Health and Nutrition Policies and Guidelines, by ECAR country'
  const table2Desc = 'Laws and regulations on infant formula/infant formula, by ECAR country'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowText(false)
    setShowTable2(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowText(false)
    setShowTable1(false)
  }

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="Enabling  environment" />
      <article>
        <PageTitle title = { pageTitle } >
          {!showText &&
            <TitleButton 
              showHandler = { showTextHandler }
              seeText = { 'See Text: '}
              icon = {'icon-text-icon'}
              description = { pageTitle }
            />
          }
          {!showTable1 &&
            <TitleButton 
              showHandler = { showTable1Handler }
              seeText = { 'See Table 6: '}
              icon = {'icon-tables-icon'}
              description = { table1Desc }
            />
          }
          {!showTable2 &&
            <TitleButton 
              showHandler = { showTable2Handler }
              seeText = { 'See Table 7: '}
              icon = {'icon-tables-icon'}
              description = { table2Desc }
            />
          }
        </PageTitle> 
        {/* TEXT */}
        { showText &&
          <>
            <CoverImg SrcImg = { Image } />
            <div className="article_content col2">
              <p className="noColumnBreak">A key weakness of the enabling environment in the ECAR region is the lack of national dietary guidelines in the six focus countries, following the review of policies and associated country documents.</p>
            </div>
          </>
        }
        {/* TABLE 1 */}
        { showTable1 && 
          <div className="article_content flex flex-col">
            <TableTitle
              title={'Table 6'}
              description={ table1Desc }
            />
            <Table06 />
          </div>
        } 
        {/* TABLE 2 */}
        { showTable2 && 
          <div className="article_content flex flex-col">
            <TableTitle
              title={'Table 7'}
              description={ table2Desc }
            />
            <Table07 />
          </div>
        } 
      </article>
    </div>
  )
}

export default Enabling
