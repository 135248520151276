import { useState } from 'react'
// Import Components
import PageTitle from '../../components/PageTitle'
import Footnote from '../../components/Footnote'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
// Import Content
import Table01 from '../../page_elements/tables/Table01'


// Component
const Methods = () => {

  const pageTitle = 'Methods'
  const tableDesc = 'IYCF indicator definitions, according to WHO/UNICEF'


  const [showText, setShowText] = useState(true);
  const [showTable, setShowTable] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable(false)
  }
  function showTableHandler(e) {
    e.preventDefault();
    setShowTable(!showTable)
    setShowText(false)
  }

  return (
    <> 
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText = { 'See Text: '}
            icon = {'icon-text-icon'}
            description = { pageTitle }
          />
        }
        {!showTable &&
          <TitleButton 
            showHandler = { showTableHandler }
            seeText = { 'See Table 1: '}
            icon = {'icon-tables-icon'}
            description = { tableDesc }
          />
        }
      </PageTitle> 
      {/* TEXT */}
      { showText && 
        <div className="article_content col2">
          <p>The landscape analyses was framed around the 2020 UNICEF programming guidance to Improve the Diets of Young Children During the Complementary Feeding Period , and the findings were organized around five key actions as highlighted in the UNICEF SOWC<Footnote sup={'1'} text={'2The State of the World’s Children 2019. Children, Food and Nutrition: Growing well in a changing world. UNICEF, New York.'}/> report, to reduce malnutrition and improve children’s diets: </p>
          <ul className="bullets">
            <li>Empower families, children and young people to demand nutritious food.</li>
            <li>Drive food suppliers to do the right thing for children.</li>
            <li>Build healthy food environments for all children.</li>
            <li>Collect, analyze and use good-quality data and evidence regularly to guide action and track progress.</li>
            <li>Mobilize supportive systems – health, water and sanitation, education and social protection – to scale up nutrition results for all children.</li>
          </ul>
          <p>The methodology included the following components 1) literature and policy review of both peer-reviewed articles, grey literature and country documents, 2) descriptive analyses of national level survey data (i.e. Demographic Health Surveys, Multiple Indicator Cluster Surveys) for all countries in the region are presented - with the exception of infant feeding patterns (six focus countries) and trends in complementary feeding (two of six focus countries), and 3) qualitative  in-depth interviews with key informants from six focus countries in region.  The six focus countries were chosen as representative of the ECAR region, and were categorized in the following sub-regions: Balkan (i.e. BiH and Serbia), Caucasus (Armenia and Georgia) and Central Asia (Kazakhstan, Tajikistan) countries.</p>
        </div>
      }
      {/* TABLE */}
      { showTable && 
        <div className="article_content flex flex-col">
          <TableTitle
            title={'Table 1'}
            description={ tableDesc }
          />
          <Table01 />
        </div>
      } 
    </>
  ) 
}

export default Methods
