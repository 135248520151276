import React from 'react';
import ReactTooltip from 'react-tooltip';
import SortableData from '../../constants/SortableData'
// Import Content
import NoteBox1 from '../../page_elements/figures/NoteBox1'

// Component
const Figure05 = () => {

  const topValue = 100;
  const charData = React.useMemo( () => [
    { 
      id: 1,
      country: 'Uzbekistan',
      classItem: 'uzbekistan_5',
      value: 81.8,
      perc: (81.8 * 100) / topValue,
    },
    { 
      id: 2,
      country: 'Kyrgyzstan',
      classItem: 'kyrgyzstan_5',
      value: 77.4,
      perc: (77.4 * 100) / topValue,
    },
    { 
      id: 3,
      country: 'Tajikistan',
      classItem: 'Ttjikistan_5',
      value: 72.2,
      perc: (72.2 * 100) / topValue,
    },
    { 
      id: 4,
      country: 'Turkmenistan',
      classItem: 'turkmenistan_5',
      value: 64.1,
      perc: (64.1 * 100) / topValue,
    },
    { 
      id: 5,
      country: 'Kazakhstan',
      classItem: 'kazakhstan_5',
      value: 59.8,
      perc: (59.8 * 100) / topValue,
    },
    { 
      id: 6,
      country: 'Azerbaijan',
      classItem: 'azerbaijan_5',
      value: 42.9,
      perc: (42.9 * 100) / topValue,
    },
    { 
      id: 7,
      country: 'Armenia',
      classItem: 'armenia_5',
      value: 36.0,
      perc: (36.0 * 100) / topValue,
    },
    { 
      id: 8,
      country: 'Georgia',
      classItem: 'georgia_5',
      value: 31.5,
      perc: (31.5 * 100) / topValue,
    },
    { 
      id: 9,
      country: 'Turkey',
      classItem: 'turkey_5',
      value: 65.6,
      perc: (65.6 * 100) / topValue,
    },
    { 
      id: 10,
      country: 'Albania',
      classItem: 'albania_5',
      value: 58.4,
      perc: (58.4 * 100) / topValue,
    },
    { 
      id: 11,
      country: 'Republic of Moldova',
      classItem: 'moldova_5',
      value: 48.4,
      perc: (48.4 * 100) / topValue,
    },
    { 
      id: 12,
      country: 'Ukraine',
      classItem: 'ukraine_5',
      value: 37.9,
      perc: (37.9 * 100) / topValue,
    },
    { 
      id: 13,
      country: 'North Macedonia',
      classItem: 'macedonia_5',
      value: 33.8,
      perc: (33.8 * 100) / topValue,
    },
    { 
      id: 14,
      country: 'Belrus',
      classItem: 'belrus_5',
      value: 27.9,
      perc: (27.9 * 100) / topValue,
    },
    { 
      id: 15,
      country: 'Serbia',
      classItem: 'serbia_5',
      value: 24.6,
      perc: (24.6 * 100) / topValue,
    },
    { 
      id: 16,
      country: 'Montenegro',
      classItem: 'montenegro_5',
      value: 23.9,
      perc: (23.9 * 100) / topValue,
    },
    { 
      id: 17,
      country: 'BiH',
      classItem: 'bih_5',
      value: 12.4,
      perc: (12.4 * 100) / topValue,
    }
  ])

    
  const { items, requestSort, sortConfig } = SortableData(charData);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
      <div className="barChartBox">
        <div className="barChart">
          {items.map(row => {
            return (
              <div className="chartRow" id={row.id} key={row.id}>
                <div className="labelBox">{ row.country }</div>
                <div className="bars">
                  {/* FIGURE */}
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#3b4395",
                        width: `${row.perc}%`,
                        height: "18px",
                      }}
                      data-tip data-for={row.classItem}
                    ></a>
                    <ReactTooltip id={row.classItem} className='barTooltipe' aria-haspopup='true'>
                      <h3>{row.country}</h3>
                      <p className="tollValue">{row.value}</p>
                    </ReactTooltip>
                  </>
                </div>
              </div>
            )
          })}
        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div className="sm:hidden"><span>10</span></div>
            <div><span>20</span></div>
            <div className="sm:hidden"><span>30</span></div>
            <div><span>40</span></div>
            <div className="sm:hidden"><span>50</span></div>
            <div><span>60</span></div>
            <div className="sm:hidden"><span>70</span></div>
            <div><span>80</span></div>
            <div className="sm:hidden"><span>90</span></div>
            <div><span>100</span></div>
          </div>
        </div>
      </div>
      {/* INFO BOX */}
      <NoteBox1 />
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box sortBtnBox">
          {/* SORT COUNTRY */}
          <button
            className={`${getClassNamesFor('country')} mr-6 ani03s`}
            type="button"
            onClick={() => requestSort('country')}
          >Country</button>
          {/* SORT VALUE */}
          <button
            className={`${getClassNamesFor('value')} mr-6 ani03s`}
            type="button"
            onClick={() => requestSort('value')}
          >Percentage</button>
          
        </div>
      </div>
    </>
  )
}

export default Figure05 
