// Import Components
import PageTitle from '../../components/PageTitle'
import Breadcrumbs from '../../components/Breadcrumbs'

// Component
const Key  = () => {

  const pageTitle = 'Key Recommendations'

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="Key Recommendations" />
      <article>
        <PageTitle title = { pageTitle } />
        <div className="article_content col2">
        <h3>Overall recommendations:</h3>
        <p>In ECAR, overall key recommendations for improving young children’s diets across  health, food and social protection systems include:</p>
        <ul className="bullets">
          <li>Develop national nutrition food-based dietary guidelines, which includes guidance on feeding processed foods to infants and young children, in relation to global standards</li>
          <li>Strengthen the capacity of health providers to counsel on breastfeeding and  complementary feeding, including the harms of infant formula, through pre-service and/or in-service training, including physicians, midwives, nurses and nutritionists</li>
          <li>Update the law on protection and promotion of breastfeeding, and prohibit breastmilk substitutes, to include stricter enforcements on advertising, marketing, monitoring and enforcement of consequences for Code violations, in alignment with WHA resolution 69.9</li>
          <li>Develop and integrate complementary feeding indicators into routine health monitoring information systems</li>
          <li>Develop social protection policies and programming to improve the diets of young children,  as part of efforts targeting poor and vulnerable families</li>
        </ul>
        <h3>Subregion recommendations:</h3>
        <p className="text-main_blue halfPad">Balkan countries</p>
        <ul className="bullets">
          <li>Update policies and guidelines on complementary feeding to ensure they are harmonized and updated according to global recommendations, and address fears regarding food allergies, and food taboos</li>
          <li>Address human resource constraints and shortage of health providers</li>
          <li>Strengthen guidance on the harms, promotion and distribution of infant formula in health facilities</li>
          <li>Fully implement the International Code of Marketing of Breastmilk Substitutes  to monitor and enforce  violations for health providers/ health organizations that promote or distribute breastmilk substitutes and benefit  monetarily , which will enforce WHA Resolution 69.9.</li>
          <li>Mothers should be supported through counselling structures in hospitals, clinics and community and via Baby-Friendly Hospital Initiative throughout the country.</li>
          <li>Enforce legislation on processed food high in fat, sugar and/or salt) for children</li>
        </ul>
        <div className="noColumnBreak">
          <p className="text-main_blue halfPad">Caucasus countries</p>
          <ul className="bullets">
            <li>Update manuals and guidelines on IYCF, including complementary feeding (which were developed in 2015), to emphasize the need for dietary diversity, reduce intake of carbohydrates, and overfeeding to prevent overweight/obesity for young children</li>
            <li>Address human resource constraints and shortage of health providers at the primary health care level in rural areas (i.e. funding, incentives, distribution of work for home visiting vs. outpatient clinics)</li>
            <li>Improve and expand awareness of optimal complementary feeding practices via  mass media (TV, radio)  and social media (i.e. Facebook groups) to reinforce evidence-based  information for parents, grandparents and extended family members on complementary feeding practices</li>
            <li>Enforce  legislation on advertisement /ban of sale of infant formula, as well as  and processed food (high in saturated and trans fats, free sugars, salt) for children.</li>
            <li>Revise the existing guidelines on BFHI, based on the newly released WHO BFHI guidelines and implement  BFHI nation-wide in maternity health facilities, which can aid to protect and promote breastfeeding in health facilities, and aid with monitoring of Code violations</li>
            <li>Generate greater public awareness on breastfeeding and complementary feeding, as well as the harms of BMS, the sufficiency of breastmilk only (i.e. for the first 6 months),  and consumption of processed foods for young children</li>
          </ul>
        </div>
        <p className="text-main_blue halfPad">Central Asian countries</p>
        <ul className="bullets">
          <li>Minimize exclusion of smallholder farmers to markets   to facilitate access of families to nutritious foods and vegetables, fruits, pulses and wholegrain foods</li>
          <li>Address mothers and influential family members about the “why” of a high‐quality, diverse diet, including food taboos,, and on “how” to economically diversify diets, as well as food storage or preservation methods to overcome seasonal scarcity.</li>
          <li>Develop nutrition education strategies include point‐of‐purchase campaigns to improve availability and appeal of IYCF‐friendly foods, as well as influencing other key household members through mosques, schools, and health care providers.</li>
          <li>Assess and monitor compliance of salt and TFA levels in foods, in partnership with local food suppliers, through the national program on NCD prevention and control, as an entry point</li>
          <li>Adopt salt reduction targets for industrially produced foods (e.g. savory snacks, bread, drinks) for young children</li>
          <li>Develop legislative measures to restrict and regulate  TV marketing of processed foods and drinks  to young children</li>
        </ul>
      </div>
      </article>
    </div>
  )
}

export default Key
