import { useState } from 'react'
// Import Components
import PageTitle from '../../components/PageTitle'
// Component
const Availability2  = () => {

  const pageTitle = 'Availability, quality and coverage of social protection services'
  

  return (
    <> 
      <PageTitle title = { pageTitle } />
      <div className="article_content col2">
        <p>In the ECAR region, while social protection programs exist, none of the countries have designated social protection policies  specifically targeting improvement of young children’s  diets in households affected by food insecurity or poverty.  The benefits for families with children, and use of  these benefits for purchasing foods for young children is often unknown, according to key informant interviews.   Further, social protection programs vary across the six focus countries within the Balkan, Caucasus and Central Asian sub regions, as described below.</p>
        <div className="noColumnBreak">
          <h3>Balkan Countries</h3>
          <p><span className="font-bold">Serbia:</span> Social protection system has different funding sources at central, state, and municipal level. Benefits are provided by different social protection measures, including a designated child allowance which includes 1) cash assistance for newborn child  and 2) assistance to mothers of children in the first 6 months of life. A few cash benefits are provided to mothers of children 0-12 or 0-18 months of age, yet how families use these benefits and if foods are purchased for young children was  unknown. There was  also lack of budget, legislation and the targeting and eligibility criteria may be too strict, as many families were  without assistance.</p>
        </div>
        <div className="noColumnBreak">
          <h3>Caucasus countries</h3>
          <p><span className="font-bold">Georgia:</span>  social protection services do not target food or interventions for improving young child diets, though food and/or cash vouchers are received by families who are eligible.</p>
        </div>
        <div className="noColumnBreak">
          <h3>Central Asian countries</h3>
          <p>In <span className="font-bold">Kazakhstan</span>,  food baskets can be provided to the family, if they are eligible according to collective family income.</p>
          <p>In <span className="font-bold">Tajikistan</span>, targeted social assistance (TSA) targets the poorest 15% of the population, and amounts to TJS 400 per year (TJS 33 per month), and school meals are provided to those in the first four years of primary school, though are not targeted to children less than five years of age.   Social safety nets can be used to increase access for to food for young children, yet the current level of benefit was  not sufficient for the population be able to afford nutritious foods, nor it was  not known if those funds are spent on foods for young children.</p>
        </div>
      </div>
    </>
  )
}

export default Availability2
