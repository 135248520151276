import { useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
// Import Pages
import Caregiver from '../adequate_p/Caregiver'
import Social from '../adequate_p/Social'

// Component
const Adequate_p = () => {

  const caregiver = useRef();
  const social = useRef();

  const location = useLocation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

  useEffect(() => {
    if(location.hash === '#social-norms') {
      scrollToRef(social);
    }
  });

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="Adequate Practices" />
      <article>
        <section id='caregiver' ref={caregiver} > 
          <Caregiver />
        </section>
        <section id='social' ref={social} > 
          <Social />
        </section>
      </article>
    </div>
  ) 
}

export default Adequate_p
