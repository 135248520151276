// Import Components
import PageTitle from '../../components/PageTitle'
// Component
const Introduction = () => {

  const pageTitle = 'Introduction'

  return (
    <> 
      <PageTitle title={ pageTitle } /> 
      <div className="article_content col2">
        <p>Optimal complementary feeding practices, as part of infant and young child feeding (IYCF), is critical to child growth and development and the prevention of micronutrient deficiencies and all forms of child malnutrition (i.e. wasting, stunting, overweight, and obesity).</p>
        <p>Inadequate quality and/or quantity of first foods, poor feeding practices and increased rates of infection have been associated with declines in child height-for-age or length-for-age in most countries. Moreover, while interventions on complementary feeding have largely focused on undernutrition, the increasing importance of child overweight in relation to processed and/or excess food consumption are key to understanding suboptimal complementary feeding practices and food environments.</p>
      </div>
    </>
  ) 
}

export default Introduction
