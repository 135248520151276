import { useState } from 'react'
// Import Components
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
// Import Content
import Table02 from '../../page_elements/tables/Table02'
import Table03 from '../../page_elements/tables/Table03'
// Component
const Desirability  = () => {

  const pageTitle = 'Desirability and convenience'
  const table1Desc = 'Local complementary feeding recipes, by ECAR focus country'
  const table2Desc = 'CACF nutrient content and labelling, select ECAR countries (* denote failure to meet requirements)'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
    setShowTable2(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowText(false)
    setShowTable2(false)
  }
  function showTable2Handler(e) {
    e.preventDefault();
    setShowTable2(!showTable2)
    setShowText(false)
    setShowTable1(false)
  }

  return (
    <> 
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText = { 'See Text: '}
            icon = {'icon-text-icon'}
            description = { pageTitle }
          />
        }
        {!showTable1 &&
          <TitleButton 
            showHandler = { showTable1Handler }
            seeText = { 'See Table 2: '}
            icon = {'icon-tables-icon'}
            description = { table1Desc }
          />
        }
        {!showTable2 &&
          <TitleButton 
            showHandler = { showTable2Handler }
            seeText = { 'See Table 3: '}
            icon = {'icon-tables-icon'}
            description = { table2Desc }
          />
        }
      </PageTitle> 
      {/* TEXT */}
      { showText && 
        <div className="article_content col2">
          <p className="noColumnBreak">In Balkan countries, convenience, lack of time for food preparation, and  parents’ lack of knowledge of  healthy foods are key drivers of food choice, according to stakeholders’ experiences, as illustrated below.</p>
          <p className="noColumnBreak">In Caucasus countries, while processed food is available in markets, parents prefer to prepare local homemade foods for their children due to high cost.  The demand and desirability for processed foods is more widespread amongst older children (school children, adolescents) than for young children.</p>
          <p className="noColumnBreak">In Central Asian countries, cost, convenience, desirability and acceptability of processed foods influence food purchases and processed food consumption in school-age children and adults in Kazakhstan.  While most consumption has been noted in school age children and adults, it is increasingly seen in young children, and can affect children’s preferences and demand for energy-dense foods, given these foods are consumed by family members.</p>
          <p className="noColumnBreak">In an assessment of a sample of commercially available complementary foods (CACFs) from ECAR focus countries, shown in Table 3, gaps exist in content on labelling, and disclosure of information on trans fatty acids, sugar content, age restrictions, as well as providing clear labelling (see * for gaps in content in comparison to standards and restrictions set by WHO European Regional Office). The nutrient content of these foods show high levels of sugar, sodium, and/or calories or low levels of protein (i.e. meat),  in comparison to nutrient composition standards for CACFs, as well as lack of clarity on whether trans fatty acids is contained in these foods.</p>
        </div>
      }
      {/* TABLE 1 */}
      { showTable1 && 
        <div className="article_content flex flex-col">
          <TableTitle
            title={'Table 2'}
            description={ table1Desc }
          />
          <Table02 />
        </div>
      } 
      {/* TABLE 2 */}
      { showTable2 && 
        <div className="article_content flex flex-col">
          <TableTitle
            title={'Table 3'}
            description={ table2Desc }
          />
          <Table03 />
        </div>
      } 
    </>
  )
}

export default Desirability 
