import React from 'react';
import ReactTooltip from 'react-tooltip';
import SortableData from '../../constants/SortableData'
// Import Content
import NoteBox1 from '../../page_elements/figures/NoteBox1'

// Component
const Figure01 = () => {

  const topValue = 100;
  const charData = React.useMemo( () => [
    { 
      id: 1,
      country: 'Introduction of solid, semi-solid or soft foods, 6-8 months',
      classItem: 'introduction',
      value: 75.0,
      perc: (75.0 * 100) / topValue,
      color: '#3b4395'
    },
    { 
      id: 2,
      country: 'Early initiation of breastfeeding, 0-5 months',
      classItem: 'early',
      value: 74.0,
      perc: (74.0 * 100) / topValue,
      color: '#eb6209'
    },
    { 
      id: 3,
      country: 'Continued breastfeeding at 1 year, 12-15 months',
      classItem: 'continued',
      value: 67.0,
      perc: (67.0 * 100) / topValue,
      color: '#e1dad3'
    },
    { 
      id: 4,
      country: 'Exclusive breastfeeding, 0-5 months',
      classItem: 'exclusive',
      value: 42.0,
      perc: (42.0 * 100) / topValue,
      color: '#fdc300'
    },
    { 
      id: 5,
      country: 'Continued breastfeeding at 2 years, 20-23 months',
      classItem: 'continued2',
      value: 31.0,
      perc: (31.0 * 100) / topValue,
      color: '#009fe3'
    }
  ])

  return (
    <>
      <div className="barChartBox">
        <div className="barChart">
          {charData.map(row => {
            return (
              <div className="chartRow" id={row.id} key={row.id}>
                <div className="labelBox">{ row.country }</div>
                <div className="bars">
                  {/* FIGURE */}
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: `${row.color}`,
                        width: `${row.perc}%`,
                        height: "50px",
                      }}
                      data-tip data-for={row.classItem}
                    ></a>
                    <ReactTooltip id={row.classItem} className='barTooltipe' aria-haspopup='true'>
                      <h3>{row.country}</h3>
                      <p className="tollValue">{row.value}</p>
                    </ReactTooltip>
                  </>
                </div>
              </div>
            )
          })}
        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div className="sm:hidden"><span>10</span></div>
            <div><span>20</span></div>
            <div className="sm:hidden"><span>30</span></div>
            <div><span>40</span></div>
            <div className="sm:hidden"><span>50</span></div>
            <div><span>60</span></div>
            <div className="sm:hidden"><span>70</span></div>
            <div><span>80</span></div>
            <div className="sm:hidden"><span>90</span></div>
            <div><span>100</span></div>
          </div>
        </div>
      </div>
      {/* INFO BOX */}
      <NoteBox1 />
    </>
  )
}

export default Figure01 
