import { useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
// Import Pages
import Introduction from '../executive/Introduction'
import Objectives from '../executive/Objectives'
import Methods from '../executive/Methods'

// Component
const Executive = () => {

  const introduction = useRef();
  const objectives = useRef();
  const methods = useRef();

  const location = useLocation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

  useEffect(() => {
    if(location.hash === '#objectives') {
      scrollToRef(objectives);
    }
    if(location.hash === '#methods') {
      scrollToRef(methods);
    }
  });

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="EXECUTIVE SUMmARY" />
      <article>
        <section id='introduction' ref={introduction} > 
          <Introduction />
        </section>
        <section id='objectives' ref={objectives} > 
          <Objectives />
        </section>
        <section id='methods' ref={methods} > 
          <Methods />
        </section>
      </article>
    </div>
  ) 
}

export default Executive
