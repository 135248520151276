import { useState } from "react";
// Import Components
import PageTitle from '../../components/PageTitle'
import Breadcrumbs from '../../components/Breadcrumbs'
import TableTitle from '../../components/TableTitle'
// Import Content
import Figure01 from '../../page_elements/figures/Figure01'
import Figure02 from '../../page_elements/figures/Figure02'
import Figure03 from '../../page_elements/figures/Figure03'
import Figure04 from '../../page_elements/figures/Figure04'
import Figure05 from '../../page_elements/figures/Figure05'
import Figure06 from '../../page_elements/figures/Figure06'
import Figure07 from '../../page_elements/figures/Figure07'
import Figure08 from '../../page_elements/figures/Figure08'
import Figure09 from '../../page_elements/figures/Figure09'
import Figure10 from '../../page_elements/figures/Figure10'
import Figure11 from '../../page_elements/figures/Figure11'
import Figure12 from '../../page_elements/figures/Figure12'

// Component
const Findings = () => {

  const pageTitle = 'Status of key complementary feeding indicators'
  const figure1Desc = 'Regional IYCF indicators, ECAR region, 2013-2019'
  const figure2Desc = 'Percentage of infants  put to the breast within the first hour of childbirth (early Initiation of breastfeeding (BF)), 2011-2018'
  const figure3Desc = 'Percentage of infants, 0-5 months of age, exclusively breastfeeding, by ECAR country (n=18), 2011-2018'
  const figure4Desc = 'Percentage of infants, 6-8 months of age, fed solid, semi-solid or soft foods in ECAR, by country, 2012-2018'
  const figure5Desc = 'Percentage of infants, continued breastfeeding at 1 year (i.e. 12-15 months) in ECAR, by country (n=17), 2012-2018'
  const figure6Desc = 'Percentage of infants, continued breastfeeding at 2 years (20-23 months), by ECAR country, (n=17), 2011-2018'
  const figure7Desc = 'Percentage of children, 6-23 months of age, who received minimum meal frequency (MMF, 2011-2017, (n=13)'
  const figure8Desc = 'Percentage of children, 6-23 months of age, who received minimum dietary diversity (MDD) requirements, by ECAR  country (n=10), 2005-2018'
  const figure9Desc = 'Percentage of children, 6-23 months of age who received a minimum acceptable diet (MAD) in  ECAR, by country (n=8), 2013-2017'
  const figure10Desc = 'Proportion of infants who received breastmilk only or breastmilk and other milks, water, formula or foods, 0-5 months of age, by ECAR focus country (n=6), 2005-2015'
  const figure11Desc = 'Trends in complementary feeding, child stunting and overweight, Armenia 2010- 2016'
  const figure12Desc = 'Trends in complementary feeding and child stunting and overweight, Tajikistan, 2012-2017'

  const [showFiguresG1Menu, setShowFiguresG1Menu] = useState(false);
  const [showFiguresG2Menu, setShowFiguresG2Menu] = useState(false);
  const [showFigures02, setShowFigures02] = useState(true);
  const [showFigures03, setShowFigures03] = useState(false);
  const [showFigures04, setShowFigures04] = useState(false);
  const [showFigures05, setShowFigures05] = useState(false);
  const [showFigures06, setShowFigures06] = useState(false);
  const [showFigures07, setShowFigures07] = useState(false);
  const [showFigures08, setShowFigures08] = useState(false);
  const [showFigures09, setShowFigures09] = useState(false);
  const [showFigures11, setShowFigures11] = useState(true);
  const [showFigures12, setShowFigures12] = useState(false);

  function setShowFigures02Handler(e) {
    e.preventDefault();
    setShowFiguresG1Menu(false);
    setShowFigures02(!showFigures02);
    setShowFigures03(false);
    setShowFigures04(false);
    setShowFigures05(false);
    setShowFigures06(false);
    setShowFigures07(false);
    setShowFigures08(false);
    setShowFigures09(false);
  }
  function setShowFigures03Handler(e) {
    e.preventDefault();
    setShowFiguresG1Menu(false);
    setShowFigures02(false);
    setShowFigures03(!showFigures03);
    setShowFigures04(false);
    setShowFigures05(false);
    setShowFigures06(false);
    setShowFigures07(false);
    setShowFigures08(false);
    setShowFigures09(false);
  }
  function setShowFigures04Handler(e) {
    e.preventDefault();
    setShowFiguresG1Menu(false);
    setShowFigures02(false);
    setShowFigures03(false);
    setShowFigures04(!showFigures04);
    setShowFigures05(false);
    setShowFigures06(false);
    setShowFigures07(false);
    setShowFigures08(false);
    setShowFigures09(false);
  }
  function setShowFigures05Handler(e) {
    e.preventDefault();
    setShowFiguresG1Menu(false);
    setShowFigures02(false);
    setShowFigures03(false);
    setShowFigures04(false);
    setShowFigures05(!showFigures05);
    setShowFigures06(false);
    setShowFigures07(false);
    setShowFigures08(false);
    setShowFigures09(false);
  }
  function setShowFigures06Handler(e) {
    e.preventDefault();
    setShowFiguresG1Menu(false);
    setShowFigures02(false);
    setShowFigures03(false);
    setShowFigures04(false);
    setShowFigures05(false);
    setShowFigures06(!showFigures06);
    setShowFigures07(false);
    setShowFigures08(false);
    setShowFigures09(false);
  }
  function setShowFigures07Handler(e) {
    e.preventDefault();
    setShowFiguresG1Menu(false);
    setShowFigures02(false);
    setShowFigures03(false);
    setShowFigures04(false);
    setShowFigures05(false);
    setShowFigures06(false);
    setShowFigures07(!showFigures07);
    setShowFigures08(false);
    setShowFigures09(false);
  }
  function setShowFigures08Handler(e) {
    e.preventDefault();
    setShowFiguresG1Menu(false);
    setShowFigures02(false);
    setShowFigures03(false);
    setShowFigures04(false);
    setShowFigures05(false);
    setShowFigures06(false);
    setShowFigures07(false);
    setShowFigures08(!showFigures08);
    setShowFigures09(false);
  }
  function setShowFigures09Handler(e) {
    e.preventDefault();
    setShowFiguresG1Menu(false);
    setShowFigures02(false);
    setShowFigures03(false);
    setShowFigures04(false);
    setShowFigures05(false);
    setShowFigures06(false);
    setShowFigures07(false);
    setShowFigures08(false);
    setShowFigures09(!showFigures09);
  }
  function setShowFigures11Handler(e) {
    e.preventDefault();
    setShowFiguresG2Menu(false);
    setShowFigures11(!showFigures09);
    setShowFigures12(false);
  }
  function setShowFigures12Handler(e) {
    e.preventDefault();
    setShowFiguresG2Menu(false);
    setShowFigures11(false);
    setShowFigures12(!showFigures09);
  }


  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="Findings" />
      <article>
        <PageTitle title = { pageTitle } />
        <div className="article_content">
          <TableTitle
            title={'Figure 1'}
            description={ figure1Desc }
          />
          <Figure01 />

          <p className="pt-4 my-6">The regional estimate of early initiation of breastfeeding  was 74%. </p>
          
          <div className="w-full flex pb-1 relative">
            <div 
            className="
              max-w-100 min-w-100 xs:max-w-80 xs:min-w-80
              bg-purple text-white font-bold text-sm
              xs:text-xs p-1 align-top uppercase
            ">
              <button onClick={() => setShowFiguresG1Menu(!showFiguresG1Menu) }>
                {showFigures02 &&
                  <span className="font-bold">FIGURE 2</span>
                }
                {showFigures03 &&
                  <span className="font-bold">FIGURE 3</span>
                }
                {showFigures04 &&
                  <span className="font-bold">FIGURE 4</span>
                }
                {showFigures05 &&
                  <span className="font-bold">FIGURE 5</span>
                }
                {showFigures06 &&
                  <span className="font-bold">FIGURE 6</span>
                }
                {showFigures07 &&
                  <span className="font-bold">FIGURE 7</span>
                }
                {showFigures08 &&
                  <span className="font-bold">FIGURE 8</span>
                }
                {showFigures09 &&
                  <span className="font-bold">FIGURE 9</span>
                }
                { showFiguresG1Menu ?
                  <span className="pl-2 pt-1 icon-close float-right"></span>
                :  <span className="pl-2 pt-1 icon-down float-right"></span> }
              </button>        
              
              { showFiguresG1Menu &&
                <div className="absolute w-full bg-purple z-10 left-0 top-8 p-2 flex flex-col">
                  {!showFigures02 &&
                    <button 
                      className="p-1 hover:opacity-80"
                      onClick={ setShowFigures02Handler }
                      >
                      <TableTitle
                        title={'Figure 2'}
                        description={ figure2Desc }
                      />
                    </button>
                  }
                  {!showFigures03 &&
                    <button
                      className="p-1"
                      onClick={ setShowFigures03Handler }
                    >
                      <TableTitle
                        title={'Figure 3'}
                        description={ figure3Desc }
                      />
                    </button>
                  }
                  {!showFigures04 &&
                    <button
                      className="p-1"
                      onClick={ setShowFigures04Handler }
                    >
                    <TableTitle
                      title={'Figure 4'}
                      description={ figure4Desc }
                    />
                    </button>
                  }
                  {!showFigures05 &&
                    <button
                      className="p-1"
                      onClick={ setShowFigures05Handler }
                    >
                    <TableTitle
                      title={'Figure 5'}
                      description={ figure5Desc }
                    />
                    </button>
                  }
                  {!showFigures06 &&
                    <button
                      className="p-1"
                      onClick={ setShowFigures06Handler }
                    >
                    <TableTitle
                      title={'Figure 6'}
                      description={ figure6Desc }
                    />
                    </button>
                  }
                  {!showFigures07 &&
                    <button
                      className="p-1"
                      onClick={ setShowFigures07Handler }
                    >
                    <TableTitle
                      title={'Figure 7'}
                      description={ figure7Desc }
                    />
                    </button>
                  }
                  {!showFigures08 &&
                    <button
                      className="p-1"
                      onClick={ setShowFigures08Handler }
                    >
                    <TableTitle
                      title={'Figure 8'}
                      description={ figure8Desc }
                    />
                    </button>
                  }
                  {!showFigures09 &&
                    <button 
                      className="p-1"
                      onClick={ setShowFigures09Handler }
                    >
                    <TableTitle
                      title={'Figure 9'}
                      description={ figure9Desc }
                    />
                    </button>
                  }
                </div>
              }
            </div>
            {showFigures02 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure2Desc}</div>
            }
            {showFigures03 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure3Desc}</div>
            }
            {showFigures04 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure4Desc}</div>
            }
            {showFigures05 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure5Desc}</div>
            }
            {showFigures06 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure6Desc}</div>
            }
            {showFigures07 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure7Desc}</div>
            }
            {showFigures08 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure8Desc}</div>
            }
            {showFigures09 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure9Desc}</div>
            }
          </div>
          
          {showFigures02 &&
            <>
              <Figure02 />
              <p className="pt-4 my-6">In the ECAR region, 42% of children are exclusively breastfed within the first 6 months of life.</p>
            </>
          }
          {showFigures03 &&
            <>
              <Figure03 />
              <p className="pt-4 my-6">In ECAR region, timely introduction of complementary foods is 75%.</p>
            </>
          }
          {showFigures04 &&
            <>
              <Figure04 />
              <p className="pt-4 my-6">Continued breastfeeding at 1 year (12-15 months) ranged widely from 12.4% (Bosnia and Herzegovina) to 81.8% (Uzbekistan).</p>
            </>
          }
          {showFigures05 &&
            <>
              <Figure05 />
              <p className="pt-4 my-6">Continued breastfeeding at 2 years was low in most ECAR countries, and ranged from 8.9% (Serbia) to 38.8% (Uzbekistan).</p>
            </>
          }
          {showFigures06 &&
            <>
              <Figure06 />
              <p className="pt-4 my-6">While a regional estimate of minimum meal frequency was not available, thirteen countries in ECAR had national level estimates available for this descriptive analysis. Minimum meal frequency ranged  from 39.9% (Tajikistan) to 95.7% (Serbia).</p>
            </>
          }
          {showFigures07 &&
            <>
              <Figure07 />
              <p className="pt-4 my-6">Data on minimum dietary diversity (MDD) was available in 10 countries in the region. Estimates ECAR range from 22.5% (Tajikistan) to 82.5% (Turkmenistan).</p>
            </>
          }
          {showFigures08 &&
            <>
              <Figure08 />
              <p className="pt-4 my-6">Only eight countries had data on minimum acceptable diet (MAD). MAD estimates ranged from 9.3% (Tajikistan) to 77.2% (Turkmenistan). Only 3 countries had a minimum acceptable diet of more than 50%: Turkmenistan at 77.2%, Serbia at 69.7% and Montenegro at 60.5%.</p>
            </>
          }
          {showFigures09 &&
            <>
              <Figure09 />
              <p className="pt-4 my-6">By 4-5 months of age, only 14.8-30.1 % of infants were fed breast milk only, with the exception of Kazakhstan.</p>
            </>
          }

          <TableTitle
            title={'Figure 10'}
            description={ figure10Desc }
          />
          <Figure10 />
          <p className="pt-4 my-6"></p>
          
          <div className="w-full flex pb-1 relative">
            <div 
            className="
              max-w-100 min-w-100 xs:max-w-80 xs:min-w-80
              bg-purple text-white font-bold text-sm
              xs:text-xs p-1 align-top uppercase
            ">
              <button onClick={() => setShowFiguresG2Menu(!showFiguresG2Menu)}>
                {showFigures11 &&
                  <span className="font-bold">FIGURE 11</span>
                }
                {showFigures12 &&
                  <span className="font-bold">FIGURE 12</span>
                }
                { showFiguresG2Menu ?
                  <span className="pl-2 pt-1 icon-close float-right"></span>
                :  <span className="pl-2 pt-1 icon-down float-right"></span> }
              </button>        
              
              { showFiguresG2Menu &&
                  <div className="absolute w-full bg-purple z-10 left-0 top-8 p-2">
                    {!showFigures11 &&
                      <button 
                        className="p-1"
                        onClick={ setShowFigures11Handler }
                        >
                        <TableTitle
                          title={'Figure 11'}
                          description={ figure11Desc }
                        />
                      </button>
                    }
                    {!showFigures12 &&
                      <button
                        className="p-1"
                        onClick={ setShowFigures12Handler }
                      >
                        <TableTitle
                          title={'Figure 12'}
                          description={ figure12Desc }
                        />
                      </button>
                    }
                  </div>
              }
            </div>
            {showFigures11 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure11Desc}</div>
            }
            {showFigures12 &&
              <div className="px-2 py-1 leading-5 flex-grow xs:text-sm">{figure12Desc}</div>
            }
          </div>
          
          {showFigures11 &&
            <>
              <p></p>
              <Figure11 />
            </>
          }
          {showFigures12 &&
            <>
              <p></p>
              <Figure12 />
            </>
          }
          
          <p className="pt-4 my-6"></p>

          <h3>Summary of descriptive analyses</h3>
          <p className="col2">Regional estimates of MMF, MDD and MAD were not available, due to lack of data for 8, 11 and 13 countries, respectively.  Most countries had high MMF (with eight out of thirteen countries in ECAR with above 70%). MDD ranged widely from 22.5% (Tajikistan) to 82.5% (Turkmenistan) in the region and about half of ECAR countries had at least 50% of children, 6-23 months of age, who reached MDD. The proportion of children who met MAD requirements was lower, with only three out of eight countries with a MAD above 60%.  Infant feeding pattern data reveal that infants are often fed other foods and liquids other than breastmilk in the first 6 months of life. Several countries have  notable use of infant formula, as well as introduction of other milks (i.e. Georgia, Serbia and Bosnia and Herzegovina, Azerbaijan), which disrupt exclusive breastfeeding. Water plus breastmilk was fed to 20%+ of infants, 0-5 months of age, in half of ECAR countries.</p>
        </div>
      </article>
    </div>
  ) 
}

export default Findings
