// Component
const Table06 = () => {
  return (
    <>
      <div className="table-scroll relative">
        {/* TABLE */}
        <table className="purple purpleCellFirstTd tableRightBorder tableBorder tableTextLC
          tableStripes w-full">
          <thead>
            <tr className="bg-purple text-white">
              <th className="bg-purple w-36">Country, (group)</th>
              <th>Health policy</th>
              <th>Nutrition policy</th>
              <th>Guidance for comp. feeding</th>
              <th>Protection and support of breastfeeding /BFHI regulations</th>
              <th>Regulations feeding of preschool/school age children</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>BiH (B)</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td></td>
              <td>X</td>
            </tr>
            <tr>
              <td>Serbia (B)</td>
              <td></td>
              <td></td>
              <td>X (project-basis)</td>
              <td>X</td>
              <td>X</td>
            </tr>
            <tr>
              <td>Armenia (C)</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td></td>
            </tr>
            <tr>
              <td>Georgia (C)</td>
              <td>X</td>
              <td></td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
            </tr>
            <tr>
              <td>Kazakhstan (CA)</td>
              <td></td>
              <td></td>
              <td>X</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Tajikistan (CA)</td>
              <td>X</td>
              <td></td>
              <td></td>
              <td>X</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="my-2 border border-solid border-txt_black_2 text-xs px-2 py-1">
        <p className="noPad">*B = Balkans, C= Caucasus,  CA = Central Asia</p>
      </div>
    </>
  )
}
export default Table06 