// Component
const Table04 = () => {
  return (
    <>
      <div className="table-scroll relative tableMinXS600">
        {/* TABLE */}
        <table className="purple table-auto tableRightBorder tableBorder tableTextLC
          tableStripes w-full">
          <thead>
            <tr className="bg-purple text-white">
              <th className="bg-purple w-96 md:w-80 sm:w-64 xs:w-20">Reasons</th>
              <th className="sm:w-32 xs:w-28 xxs:w-16">Balkans</th>
              <th className="sm:w-32 xs:w-28 xxs:w-16">Caucasus</th>
              <th className="sm:w-32 xs:w-28 xxs:w-16">Central Asia</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Incorrect/lack of knowledge  (training and/or implementation)</td>
              <td></td>
              <td>X</td>
              <td>X</td>
            </tr>
            <tr>
              <td>Time/workload</td>
              <td>X</td>
              <td>X</td>
              <td></td>
            </tr>
            <tr>
              <td>Shortage of health providers  (i.e. in rural areas/aging population of providers)</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
            </tr>
            <tr>
              <td>Lack of incentives to prioritize complementary feeding counselling (i.e. low pay)</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
            </tr>
            <tr>
              <td>No routine monitoring -  receipt of counseling on complementary feeding in HMIS</td>
              <td>X</td>
              <td>X</td>
              <td>X</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
export default Table04 