const Table03 = () => {
  return (  
    <div className="table-scroll relative">  
      <table className="tableRightBorder purple purpleCellFirstTd text-left border border-purple xs:text-xs">
        <thead>
          <tr className="text-white bg-purple">
              <th className="text-white bg-purple w-1/5 xs:w-1/12">Country</th>
              <th className="w-2/5 xs:w-5/12">Dry or instant cereal – nutrient composition standards, and labelling requirements/ promotional restrictions, WHO European Regional Office</th>
              <th className="w-2/5 xs:w-6/12">Name of CACF, nutrient content and label information, commercially available complementary food  (CACF)</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td className="bg-white">Balkan country: Serbia</td>
            <td>
              <p className="font-bold">Type of food: Dry or instant cereals/starch</p>
              <ul className="bullets">
                <li>No added sugar or sweetening agent</li>
                <li>≤ 10% by weight dried or powdered fruit</li>
                <li>{'Sodium <50mg/100kcal'}</li>
                <li>No industrially produced trans fatty acids</li>
                <li>{'≤ 4.5 g/ 100 kcals total fats < 5.5 g/100 kcal total protein'}</li>
                <li>Total sugar: front-of-pack flag if ≥ 30% total energy</li>
                <li>Age restriction: ≥ 6 months only</li>
                <li>No claims</li>
                <li>Product name/ingredient clarity</li>
                <li>No added sodium in liquid used to reconstitute product</li>
                <li>Ingredients list should state the amount of dried and powdered fruit (%)</li>
              </ul>
            </td>
            <td>
              <p className="font-bold">Lino – chocolate flavored dry porridge with hazelnuts</p>
              <ul className="bullets">
                <li>Added 38 grams of sugar</li>
                <li>Energy: 417 kcal/1762 kj</li>
                <li>No dried/powdered fruit in ingredient list</li>
                <li>No sodium</li>
                <li>6 g/100g of fat, saturated fats 1.4 grams</li>
                <li>No protein</li>
                <li>No information on industrially produced trans fatty acids*</li>
                <li>{'No label on front of pack on total sugar as > 30% of total energy*'}</li>
                <li>No age restriction*</li>
                <li>Claims: cereal with 7 vitamins, sources of protein and prepared with milk*</li>
              </ul>
            </td>
          </tr>

          <tr className="bg-purple_pale">
            <td className="bg-purple_pale">Balkan country: Serbia</td>
            <td>
              <p className="font-bold">Type of food: Pureed meals with meat or fish (without first food in product name)</p>
              <ul className="bullets">
                <li>No added sugar or sweetening agent</li>
                <li>≤ 5% by weight fruit puree with a max. of 2% from pureed dried fruit</li>
                <li>Energy density ≥ 60 kcal/100 g</li>
                <li>Total protein ≥ 3g/100 kcal from all sources (of which ≥ 2.2</li>
                <li>g/100 kcal protein from dairy if cheese mentioned in front-of-pack name)</li>
                <li>Labelling:Protein source mentioned in the product name must be ≥ 8% by weight of the total product</li>
                <li>Each named protein not less than 25% by weight of total named protein</li>
                <li>{'Sodium < 50mg/100 kcal and <50mg/100g (or < 100 mg/100 kcal and <100mg/100g if cheese is mentioned in front-of-pack name)'}</li>
                <li>No industrially produced trans fatty acids ≤ 4.5 g/100 kcal total fat</li>
              </ul>
            </td>
            <td>
              <p><span className="font-bold">Frutek -</span> porridge with beef and rice, with ingredients of water, vegetables (carrot 18%, peas17%, parsnip 5%, beef 8.5%, rice 3%-  rice starch- 2%, sunflower oil, salt, onion powder).  Allergens, does not contain: soybeans, peanuts, milk, eggs, sesame, celery, shellfish/mollusks, nuts, crustaceans, shell, gluten, mustard, sulfur dioxide or sulfite, fish</p>
              <ul className="bullets">
                <li>No added sugar, no gluten</li>
                <li>No fruit added </li>
                <li>Energy 310 KJ/100 grams – over limit*</li>
                <li>Protein 2.9g/100 grams – less than 8% of weight*</li>
                <li>{'2.9 grams protein of 100 grams, which is less than >8%*'}</li>
                <li>No other protein named</li>
                <li>0.25 g/100 grams on label- which translates to 250 mg/100mg*</li>
                <li>No trans fatty acid mentioned*, contains sunflower oil, and contains 2.4 g fat/100 grams</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td className="bg-white">Caucasus country - Armenia</td>
            <td>
              <p className="font-bold">Type of food: Dry or instant cereals/starch</p>
              <ul className="bullets">
                <li>No added sugar or sweetening agent</li>
                <li>≤ 10% by weight dried or powdered fruit</li>
                <li>{'Sodium <50mg/100kcal'}</li>
                <li>{'No industrially produced trans fatty acids'}</li>
                <li>≤ 4.5 g/ 100 kcals total fats</li>
                <li>{'< 5.5 g/100 kcal total protein'}</li>
              </ul>
              <p className="font-bold">Labelling:</p>
              <ul className="bullets">
                <li>Total sugar: front-of-pack flag if ≥ 30% total energy</li>
                <li>Age restriction: ≥ 6 months only</li>
                <li>No claims</li>
                <li>Product name/ingredient clarity</li>
                <li>No added sodium in liquid used to reconstitute product</li>
                <li>Ingredients list should state the amount of dried and powdered fruit (%)</li>
              </ul>
            </td>
            <td>
              <div className="w-100 table w-full font-bold pb-1">
                <p className="table-cell w-1/2">Nutritional fact</p>
                <p className="table-cell w-1/2">In 100g of the product</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Energy</p>
                <p className="table-cell w-1/2">358kkal</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Protein, g</p>
                <p className="table-cell w-1/2">9</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Fat, g</p>
                <p className="table-cell w-1/2">1,1</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Carbohydrates, g</p>
                <p className="table-cell w-1/2">78</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Including saccharose, g</p>
                <p className="table-cell w-1/2">13</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Prebiotic – inulin, g</p>
                <p className="table-cell w-1/2">2,5</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell">VITAMINS</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">A, mg</p>
                <p className="table-cell w-1/2">0,45</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">E, mg</p>
                <p className="table-cell w-1/2">6</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">C, mg</p>
                <p className="table-cell w-1/2">45</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">B1, mg</p>
                <p className="table-cell w-1/2">0,5</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">B2, mg</p>
                <p className="table-cell w-1/2">0,6</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">B6, mg</p>
                <p className="table-cell w-1/2">0,6</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">B12, mcg</p>
                <p className="table-cell w-1/2">0,5</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">PP, mg</p>
                <p className="table-cell w-1/2">7</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">B5, mg</p>
                <p className="table-cell w-1/2">2,35</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">B9, mcg</p>
                <p className="table-cell w-1/2">47</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Biotin, mcg</p>
                <p className="table-cell w-1/2">10</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell">MINERALS</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Ca, mg</p>
                <p className="table-cell w-1/2">510</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Na, mg</p>
                <p className="table-cell w-1/2">9</p>
              </div>
              <div className="w-100 table w-full border-b-2 border-purple">
                <p className="table-cell w-1/2">Fe, mg</p>
                <p className="table-cell w-1/2">8,5</p>
              </div>
              <div className="w-100 table w-full pb-4">
                <p className="table-cell w-1/2">I, mcg</p>
                <p className="table-cell w-1/2">50</p>
              </div>
              <p><span className="font-bold">Vinni porridge -</span> 7 cereals</p>
              <ul className="bullets">
                <li>Sugar is 13 % by weight*</li>
                <li>No mention of industrially produced trans fatty acids*</li>
                <li>Fat is less than recommendations at 1.1 per 100 grams</li>
                <li>Sodium is added – unsure if in liquid</li>
                <li>No ingredients of dried and powdered fruit listed*</li>
              </ul>
            </td>
          </tr>

          <tr className="bg-purple_pale">
            <td className="bg-purple_pale">Caucasus country: Armenia</td>
            <td>
              <p className="font-bold">Vegetable only purée </p>
              <ul className="bullets">
                <li>No added sugar or sweetening agent</li>
                <li>No added fruit/ fruit purée</li>
                <li>{'Added water < 25% by weight'}</li>
                <li>{'Sodium < 50 mg/100 kcal and < 50 mg/100 g'}</li>
                <li>No industrially produced trans fatty acids</li>
                <li>≤ 4.5g/100 kcal total fat</li>
              </ul>
              <p className="font-bold">Labelling:</p>
              <ul className="bullets">
                <li>Total sugar: front-of-pack flag if ≥ 30% total energy</li>
                <li>{'Age restriction: ≥ 6 m and < 12 m only'}</li>
                <li>No claims</li>
                <li>Product name/ingredient clarity</li>
                <li>Statement about no sucking from spouts if in pouch</li>
                <li>Ingredient list should state the amount of added water (%</li>
              </ul>
            </td>
            <td>
              <p className="font-bold">Gerber broccoli in a jar –(use of such food not commonly practiced in Armenia)</p>
              <ul className="bullets">
                <li>Composition: 80% broccoli, 20% Water</li>
                <li>Energy 21 kcal/100 grams</li>
                <li>Protein 1.8 g/ 100 grams</li>
                <li>Fat 0.2 and Carbohydrates is 3.3 g/100 grams</li>
                <li>No added sugar or fruit</li>
                <li>No sodium</li>
                <li>No age restriction added*</li>
                <li>No claims</li>
                <li>Product name/ingredient clarity*</li>
              </ul>
            </td>
          </tr>
          
          <tr>
            <td className="bg-white">Caucasus country: Georgia</td>
            <td>
              <p className="font-bold">Type of food: Dry finger foods and snacks:</p>
              <p className="font-bold">Category: Other snacks and finger foods</p>
              <ul className="bullets">
                <li>No added sugar or sweetening agent</li>
                <li>{'Total sugar < 15% of total energy (3.75 g/100 kcal) otherwise considered a sweet snack that should not be marketed (see 4.1)'}</li>
                <li>{'Sodium < 50 mg/100 kcal and <50mg/100g'}</li>
                <li>No industrially produced trans fatty acids</li>
                <li>≤ 4.5 g/100 kcal total fat</li>
                <li>≤50 kcal per portion or serve</li>
              </ul>
              <p className="font-bold">Labelling:</p>
              <ul className="bullets">
                <li>No added sugar or sweetening agent</li>
                <li>{'Total sugar < 15% of total energy (3.75 g/100 kcal) otherwise considered a sweet snack that should not be marketed (see 4.1)'}</li>
                <li>{'Sodium < 50 mg/100 kcal and <50mg/100g'}</li>
                <li>No industrially produced trans fatty acids</li>
                <li>≤ 4.5 g/100 kcal total fat</li>
                <li>≤50 kcal per portion or serve</li>
              </ul>
            </td>
            <td>
              <p className="font-bold">Liebe junior – No.1 licensed children’s biscuit, from one year of age</p>
              <p>Ingredients: Wheat flour, corn oil, sugar, corn starch, glucose-fructose syrup, eggs, milk powder, baking powder (food soda, ammonium carbonate) natural fragrance,</p>
              <ul className="bullets">
                <li>This Product contain the gluten vitamin A and E</li>
                <li>Nutrition facts  per 100 grams</li>
                <li>Energy Value 473 kcal</li>
                <li>Fat 18 grams, among them Saturated fatty acids 2.34 grams</li>
                <li>Carbohydrates, 69,7 grams</li>
                <li>sugar is 20.6 grams</li>
                <li>Protein 7.4 grams</li>
                <li>Salts 0.1 gr</li>
                <li>Label states</li>
                <li>Do not contain trans fat</li>
                <li>Do not contain palm oil</li>
                <li>Do not contain preservatives</li>
                <li>Sugar added</li>
                <li>Sodium 100 mg/100 grams – over limit</li>
                <li>High calories per portion/serving</li>
                <li>Label doesn’t mention sodium</li>
              </ul>
            </td>
          </tr>

          <tr className="bg-purple_pale">
            <td className="bg-purple_pale">Central Asian country: Kazakhstan</td>
            <td>
              <p className="font-bold">Type of food: Dry finger foods and snacks:</p>
              <p className="font-bold">Category: Other snacks and finger foods</p>
              <ul className="bullets">
                <li>No added sugar or sweetening agent</li>
                <li>{'Total sugar < 15% of total energy (3.75 g/100 kcal) otherwise considered a sweet snack that should not be marketed (see 4.1)'}</li>
                <li>{'Sodium < 50 mg/100 kcal and <50mg/100g'}</li>
                <li>No industrially produced trans fatty acids</li>
                <li>≤ 4.5 g/100 kcal total fat</li>
                <li>≤50 kcal per portion or serve</li>
              </ul>
              <p className="font-bold">Labelling:</p>
              <ul className="bullets">
                <li>No added sugar or sweetening agent</li>
                <li>{'Total sugar < 15% of total energy (3.75 g/100 kcal) otherwise considered a sweet snack that should not be marketed (see 4.1)'}</li>
                <li>{'Sodium < 50 mg/100 kcal and <50mg/100g'}</li>
                <li>No industrially produced trans fatty acids</li>
                <li>≤ 4.5 g/100 kcal total fat</li>
                <li>≤50 kcal per portion or serve</li>
              </ul>
            </td>
            <td>
              <p className="font-bold">Semper children’s biscuit with Banana from 6 months</p>
              <p>125 gr - contains vitamins, natural prebiotic, does not contain sugar, preservatives, fragrances</p>
              <p>Wheat flour, corn oil, grape juice, sunflower oil, oligofructose, corn starch, banana, eggs, milk powder,  insulin, calcium, baking powder, Fe, Iodine,  Vitamins C, B1, B2,</p>
              <ul className="bullets">
                <li>This Product contain the gluten</li>
                <li>Nutrition facts 100gr</li>
                <li>Energy Value 443 kcal</li>
                <li>Protein 8.3 gr</li>
                <li>Carbohydrates, 71.78 grams, sugar is 13,6 grams</li>
                <li>Fat 11. 79grams =  saturated fatty acids 1.08 grams</li>
                <li>Vitamin c 24,1 mg</li>
                <li>Vitamin B1 06.mg</li>
                <li>Vitamin B2 0.6 mg</li>
                <li>Niacin 6.2 mg</li>
                <li>Sodium 310 mg</li>
                <li>Calcium 474 mg</li>
                <li>Fe 14.4 mg</li>
                <li>Iodine 60 mg</li>
              </ul>
              <p>It is ready for use. You can use in dry also put the milk or tea or juice. Warm the liquid  40-50C  and put the biscuit until it is opened.</p>
              <ul className="bullets">
                <li>Sugar added; yet label says no sugar added</li>
                <li>Not sure if trans fatty acids added</li>
                <li>Sodium– over limit</li>
                <li>High calories per portion/serving</li>
                <li>Label doesn’t mention sodium</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="bg-white">Caucasus country: Georgia</td>
            <td>
              <p className="font-bold">Vegetable only purée</p>
              <ul className="bullets">
                <li>No added sugar or sweetening agent</li>
                <li>No added fruit/ fruit purée</li>
                <li>{'Added water < 25% by weight'}</li>
                <li>{'Sodium < 50 mg/100 kcal and < 50 mg/100 g'}</li>
                <li>No industrially produced trans fatty acids</li>
                <li>≤ 4.5g/100 kcal total fat</li>
              </ul>
              <p className="font-bold">Labelling:</p>
              <ul className="bullets">
                <li>Total sugar: front-of-pack flag if ≥ 30% total energy</li>
                <li>{'Age restriction: ≥ 6 m and < 12 m only'}</li>
                <li>No claims</li>
                <li>Product name/ingredient clarity</li>
                <li>Statement about no sucking from spouts if in pouch</li>
              </ul>
            </td>
            <td>
              <p><span className="font-bold">Gerber:</span> pureed carrot; pureed cauliflower</p>
              <p>Product is composed of 100 % of carrot.</p>
              <p>The nutritional value by 100 grams:</p>
              <ul className="bullets">
                <li>Energetic value is 138 kilojoule/33 of calories</li>
                <li>Proteins 0.8 gr</li>
                <li>Fat 0.2 gr</li>
                <li>Carbohydrate 70</li>
                <li>K 110 micrograms</li>
                <li>sterilized and fortified with Vitamin C</li>
                <li>“According to the law of the Republic of Kazakhstan, Belarusian Republic, and Russian Federation this product is recommended for children from 4 months.”</li>
                <li>NESTLE brand supports the WHO recommendations on exclusive breastfeeding of a child during first 6 months with a gradual introduction to the complementary meal from 6 months while keeping the breastfeeding as the basis of nutrition of the child.</li>
                <li>Consultancy of a specialist is needed. Start introduction from 1 small spoon by  gradually increase a volume.  Don’t use the iron spoon. It is ready for use. Please, keep it under the temperature of  6 30 Celsius degree. Open jar should be kept in the refrigerator no more than 24 hours. Do not use the product if it is not clicked during the opening. Date of expiration is on the top of the jar.</li>
              </ul>
              <p>*No sugar added, no information on trans fatty acids</p>
              <p>* violated age restriction – under 6 months of age</p>
              <p>* no added water was included in ingredient list</p>
            </td>
          </tr>

        </tbody>
      </table>
      
    </div>
  ) 
}
export default Table03
