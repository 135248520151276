// Import Components
import PageTitle from '../../components/PageTitle'
// Component
const Affordability = () => {

  const pageTitle = 'Affordability'

  return (
    <> 
      <PageTitle title={ pageTitle } /> 
      <div className="article_content col2">
        <p className="noColumnBreak">Cost is not viewed as a major prohibitive influence on young children’s diets in the region, according to stakeholders, yet data from the literature review provides further insight on affordability in the ECAR region.</p>
        <p className="noColumnBreak">In Central Asian countries, cost can be a prohibitive factor for increasing intake of nutrient-rich foods, according to data extracted from the literature review. According to a World Food Program analyses, food consumption patterns in Tajikistan have changed between 2003 and 2017,  dramatic increases in consumption of eggs and meat by 217 and 106 percent respectively. Vegetable oil and sugar consumption have also shown a significant increase  by  75 percent. While there are no notable difference in food consumption by household wealth status, the richest households consume more diversified diets with greater quantity and share of vegetables and animal protein (meat, milk/dairy products, eggs). Yet, two thirds of respondents named foods their household could not routinely afford (most commonly meat, followed by fruits and vegetables).</p>
        <p className="bg-main_blue p-5 mb-5 text-white text-sm noColumnBreak">“There is home production and people also purchase[foods], it is difficult to purchase all things in the village  due to lack  of funds for  seven family members;   in the mountain areas, families have livestock, and other rural areas produce vegetables and purchase meat.  For the price is high for meat, so it’s easier for families to raise poultry and eggs. – Stakeholder, Tajikistan</p>
        <p className="noColumnBreak">Rural villages typically only have small stores with limited offerings, especially during winter and early spring, due to seasonal scarcity, and freezing of road. Families with transportation can access larger markets with more favorable pricing and variety; while, poor families often pay higher prices for limited foods at village stores, of which they have access. On the national level, data from a recent study reveal that 29% to 56% of households were unable to afford nutritious diets in the four regions of Tajikistan.</p>
        <p className="bg-main_blue p-5 mb-5 text-white text-sm noColumnBreak">“Our local products are very expensive, for example 1 kilogram of oranges is $8-9 USD and the average salary is 50-60 USD.  In rural areas, 70% of families depend on agricultural production. The financial situation of the Tajik household/Tajik doesn’t allow purchase of high quality food from markets or supermarket.” – Tajik stakeholder</p> 
        <p className="noColumnBreak">Mothers who had a household garden and raised livestock and had sufficient food had high dietary diversity, whereas this child had inadequate dietary diversity, as their dietary intake were not prioritized in comparison to mothers.</p>
      </div>
    </>
  ) 
}

export default Affordability
