import { useState } from "react";
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import PolicyMenu from "../../components/PolicyMenu";
import Footnote from '../../components/Footnote'
import TitleButtonLink from '../../components/TitleButtonLink'
// Import Images
import Eeca from '../../images/kazakhstan.svg'
// Import Pdf
import pdf from '../../pdfs/PB-Kazakhstan.pdf'

// Component
const Kazakhstan = () => {

  const pageTitle = 'Policy Brief: Improving complementary feeding in Kazakhstan'

  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  const [showCountryMenu, setShowCountryMenu] = useState(false);

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="POLICY BRIEFS" />
      <article>
        <PageTitle title={ pageTitle } >
          <TitleButtonLink 
            pbUrl = { pdf }
            seeText = { 'Download: '}
            icon = {'icon-pdf'}
            description = { 'Policy Brief - Kazakhstan' }
          />
        </PageTitle>
        <div className="w-full relative pb-12">
          {/* MAP */}
          <img className="w-full" src={Eeca} />
          {/* COUNTRY MENU */}
          <div className="absolute top-0 left-0 w-60">
            <div
              onClick={e => setShowCountryMenu(!showCountryMenu)}
              className="bg-main_blue px-4 py-2 text-white hover:text-sec_blue cursor-pointer ani03s">
              POLICY BRIEFS
              { showCountryMenu ?
               <span className="pl-2 pt-1 icon-close float-right"></span> : 
               <span className="pl-2 pt-1 icon-down float-right"></span> }
            </div>
            { showCountryMenu ? <PolicyMenu active='kazakhstan' /> : <></>}
          </div>
          <button className="
          absolute map_z_info top-2 right-2
          w-6 h-6 rounded-full ani03s
          bg-txt_black_1 text-white text-sm hover:bg-gray
          "
          onClick={() => setShow(true)}
          >
            <span className="relative icon-info top-0.5"></span>
          </button>
        </div>
        <div className="article_content col2">
          <p>Europe and Central Asia Regional Office conducted a regional landscape analysis on trends and predictors of young children’s diets in select countries in the region in 2020. The findings from this analysis are intended to inform the development of a regional framework for complementary feeding that will guide countries in ECAR on policy and programming to accelerate progress toward the prevention of child stunting and obesity. This brief summarizes key findings on the drivers of young children’s diets in Kazakhstan as well as major policy recommendations.</p>
          <p className="bg-main_blue p-5 mb-5 text-white text-sm">Complementary feeding refers to the introduction of semi-solid and solid foods to complement breastfeeding and takes place between 6 months and 2 years of age. Nutrient requirements increase significantly during this period. WHO global guidance recommends that children begin consuming foods other than breastmilk at 6 months of age, children are fed a diverse array of food groups, are fed frequently throughout the day, and continue breastfeeding up to 2 years of age</p>
          <h3 className="text-lg text-main_blue">Appropriate complementary feeding is essential to avoid the double-burden of malnutrition in Kazakhstan </h3>
          <p>What, when and how babies are fed determines the quality of their diets during the first years of life – with impacts that last a lifetime. In Kazakhstan, children under five years of age,  suffer from stunting (6%), overweight (7.4%), and  anemia. Survey data from Kyzyl-Orda and east Kazakhstan in children 6-59 months of age (n=506),  the prevalence of child anemia {'(Hb< 110 g/L)'} was 58.1%. Anemia was notably higher among  6-23 month old children  (62%) in comparison to their older counter parts (24-59 months) (52.6%).<Footnote sup={'1'} text={'UNICEF, Study on Prevalence of Anemia and Iodine Deficiency among Children under Five Years of Age and their Mothers in East Kazakhstan and Kzyl-Orda Regions, 2016'} /> Survey data also reveals a high prevalence (23.2%) of vitamin A deficiency (VAD) (n= 1318 children less than 5 years of age).</p>
          <p>To improve infant and toddler diets we need to consider the range of factors – both within and outside of the household – that influence what, when and how babies eat. These factors include adequate food, services and practices and an enabling environment that support good diets in a more coordinated manner.</p>
          <h3 className="text-lg text-main_blue">Key drivers of the complementary feeding in Kazakhstan</h3>
          <p className="font-bold halfPad">Enabling environment</p>
          <p>Kazakhstan has  complementary feeding guidelines , however there are no clear targets, indicators, or interventions to improve complementary feeding practices in any country policies  or strategies. Kazakhstan also does not have a food-based dietary guideline that targets improving the diets of young children.</p>
          <p className="font-bold halfPad">Adequate Foods</p>
          <p>Nutritious foods need to be available, accessible, affordable and desirable to children and their families. There was limited evidence in BiH on the availability, accessibility, and affordability of food for young children. However, interviews with key informants indicated that availability and access is not perceived as a barrier to achieving improved complementary feeding. More evidence is required to further investigate how availability and access hinder complementary feeding practices in Kazakhstan. Commercial complementary foods, such as jarred vegetables (i.e. carrot and cauliflower) are advised to be fed from 4 months of age, which is not in line with WHO/UNICEF recommendations for timely introduction of complementary foods at 6 months of age. </p>
          <p>Processed, energy-dense food is increasingly available in urban areas, through street and market vendors, which threatens to disrupt availability of nutrient-rich foods consumed by families, and subsequently fed to young children. The FEEDcities Project of Eastern Europe and Central Asia characterized food offered in  vending sites  in Kazakhstan. While these data are not specifically characterizing foods for young children, the data point to the increasing availability of these foods in the region. There was a notable lack of availability of nutrition-dense rich fruits and vegetables, especially in urban populations (i.e. Almaty), across markets and street food vendors.  Fruit was sold by only 1.0% of vending sites, with 37% selling  only industrially processed foods (36.6%). Wide availability of sugary drinks and high content of trans fat and sodium in commercial and homemade street foods have been documented in the capital cities of Central Asia. While access to food was not of concern in urban and rural areas, seasonal availability of certain food items (i.e. fruits and vegetables) were seen to affect dietary intake. In Kazakhstan, access to foods through local shops and markets remains a vital source of nutritious foods, alongside household food production, which is increasingly important for diversification of young children’s diets in rural areas.</p>
          <p className="font-bold halfPad">Adequate Services</p>
          <p>Access to quality health and nutrition services, safe water and sanitation services, and effective social protection services for all children can help ensure their families have the support they need to provide their children with nutritious and safe foods. The absence of these services, or poor quality delivery, can instead hinder efforts to improve child diets. For example, in Kazakhstan, health providers often do not prioritize complementary feeding counseling, largely due to inadequate  job aids, a lack of  pre-service training in complementary feeding, as well as lack of clear roles and responsibilities of nutritionists.  The country also lacks a cadre of nutritionists trained in provision of infant and young child nutrition counselling, which is a key gap. Complementary feeding practices are not monitored through the health monitoring information system (HMIS), limiting the ability of experts and policy makers in the health sector to identify potential problems in real time.  In addition, while income-eligible families receive food baskets, there is no designated social protection policy to improve young children’s diets.</p>
          <p>The guidelines on feeding and caring for the development of healthy child, developed by the Ministry of Health,  recommends exclusive breastfeeding for 6 months, three meals for breastfed children (5 meals for non-breastfed) from 6- 12 months of age and a variety of complementary foods (i.e. thick rice, semolina or buckwheat porridge, boiled minced lean meat, liver, fish, eggs and beans, various vegetables, fruits and well-peeled, strained nuts. From 12 months to two years of age, it is recommended that children consume family meals with a variety of meat, vegetables, fruits and nuts, as well as dairy products (i.e. milk. Mothers are encouraged to continue breastfeeding to 2 years of age. Avoidance of specific foods at various ages is recommended (i.e. whole cow, goat, or sheep’s milk, eggs from 6-8 months), though this was not linked specifically to certain food allergies or food taboos for early childhood.  Development of local complementary feeding recipe books for young children (how to prepare meats, vegetables) is currently underway in Kazakhstan</p>
          <p className="font-bold halfPad">Adequate Practices</p>
          <p>The feeding, care and hygiene behaviors of caregivers support good nutrition for children and their families. According to key informant interviews, mothers and families’ awareness of what and how to feed children can be limited,  with regards to food preparation and recipes.  Mothers may introduce foods early before 6 months of age (such as processed food,  cookies). Meat is a primary feature of the Kazakh diet as is processed food consumption. While there is little information on food taboos, there is a misperception that breastfeeding is sufficient until one year of age, which has contributed to delayed introduction of complementary foods. Commercial complementary foods are often recommended before 4 months of age, which can disrupt exclusive breastfeeding.</p>
          <p className="font-bold halfPad">Key recommendations to improve complementary feeding</p>
          <ol className="list-decimal pl-8">
            <li>
              Develop a national food-based dietary guideline that focuses on young children, 6-24 months of age 
            </li>
            <li>
              Develop and enforce a  legislation on advertisement  of commercial infant foods Develop a social protection policy targeting improvement of young children’s diets  
            </li>
            <li>
              <p>Put in place legislative measures to restrict and regulate  TV marketing of HFSS foods and drinks  to young children</p>
              <p>Restrict or disincentivize the availability of unhealthy foods and beverages  with high levels of trans fatty acids, and salt, work with street food vendors to make street food healthier with use of  healthier ingredients (i.e. less sodium, and healthier fats)</p>
            </li>
            <li>  
              Address shortage of numbers of  health providers and nutritionists in the country
            </li>
            <li>  
              Strengthen health providers’ capacity   to provide  interpersonal counselling on complementary feeding 
            </li>
            <li>
              Integrate key IYCF Indicators into the HMIS and monitor progress            
            </li>
          </ol>
        </div> 
      </article>
      {/* MAP INFO */}
      { show && 
        <div
          onClick={closeModalHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
          <div className="px-8 py-6 bg-white relative max-w-400">
            <div
              onClick={closeModalHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </div>
            These maps are stylized and not to scale, and do not reflect a position by UNICEF on the legal status of any country or territory or the delimitation of any frontiers.
          </div>
        </div>
      }
    </div>
  ) 
}

export default Kazakhstan
