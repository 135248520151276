import { useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
// Import Pages
import Availability from '../determinants/Availability'
import Access from '../determinants/Access'
import Affordability from '../determinants/Affordability'
import Desirability from '../determinants/Desirability'

// Component
const Determinants = () => {

  const availability = useRef();
  const access = useRef();
  const affordability = useRef();
  const desirability = useRef();

  const location = useLocation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

  useEffect(() => {
    if(location.hash === '#access') {
      scrollToRef(access);
    }
    if(location.hash === '#affordability') {
      scrollToRef(affordability);
    }
    if(location.hash === '#desirability-and-convenience') {
      scrollToRef(desirability);
    }
  });

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="Determinants and drivers of adequate diets during complementary feeding periods" />
      <article>
        <section id='availability' ref={availability} > 
          <Availability />
        </section>
        <section id='access' ref={access} > 
          <Access />
        </section>
        <section id='affordability' ref={affordability} > 
          <Affordability />
        </section>
        <section id='desirability' ref={desirability} > 
          <Desirability />
        </section>
      </article>
    </div>
  ) 
}

export default Determinants
