import React from 'react';
import ReactTooltip from 'react-tooltip';
import SortableData from '../../constants/SortableData'
// Import Content
import NoteBox1 from '../../page_elements/figures/NoteBox1'

// Component
const Figure08 = () => {

  const topValue = 100;
  const charData = React.useMemo( () => [
    { 
      id: 1,
      country: 'Turkmenistan',
      classItem: 'turkmenistan_8',
      value: 82.5,
      perc: (82.5 * 100) / topValue,
    },
    { 
      id: 2,
      country: 'Kyrgyzstan',
      classItem: 'kyrgyzstan_8',
      value: 59.8,
      perc: (59.8 * 100) / topValue,
    },
    { 
      id: 3,
      country: 'Kazakhstan',
      classItem: 'kazakhstan_8',
      value: 49.0,
      perc: (49.0 * 100) / topValue,
    },
    { 
      id: 4,
      country: 'Tajikistan',
      classItem: 'Ttjikistan_8',
      value: 22.5,
      perc: (22.5 * 100) / topValue,
    },
    { 
      id: 5,
      country: 'Armenia',
      classItem: 'armenia_8',
      value: 36.3,
      perc: (36.3 * 100) / topValue,
    },
    { 
      id: 6,
      country: 'Azerbaijan',
      classItem: 'azerbaijan_6',
      value: 34.8,
      perc: (34.8 * 100) / topValue,
    },
    { 
      id: 7,
      country: 'Serbia',
      classItem: 'serbia_8',
      value: 77.0,
      perc: (77.0 * 100) / topValue,
    },
    { 
      id: 8,
      country: 'Republic of Moldova',
      classItem: 'moldova_6',
      value: 69.7,
      perc: (69.7 * 100) / topValue,
    },
    { 
      id: 9,
      country: 'Montenegro',
      classItem: 'montenegro_8',
      value: 69.1,
      perc: (69.1 * 100) / topValue,
    },
    { 
      id: 10,
      country: 'Albania',
      classItem: 'albania_8',
      value: 52.5,
      perc: (52.5 * 100) / topValue,
    }
  ])

  const { items, requestSort, sortConfig } = SortableData(charData);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
      <div className="barChartBox">
        <div className="barChart">
          {items.map(row => {
            return (
              <div className="chartRow" id={row.id} key={row.id}>
                <div className="labelBox">{ row.country }</div>
                <div className="bars">
                  {/* FIGURE */}
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#3b4395",
                        width: `${row.perc}%`,
                        height: "18px",
                      }}
                      data-tip data-for={row.classItem}
                    ></a>
                    <ReactTooltip id={row.classItem} className='barTooltipe' aria-haspopup='true'>
                      <h3>{row.country}</h3>
                      <p className="tollValue">{row.value}</p>
                    </ReactTooltip>
                  </>
                </div>
              </div>
            )
          })}
        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div className="sm:hidden"><span>10</span></div>
            <div><span>20</span></div>
            <div className="sm:hidden"><span>30</span></div>
            <div><span>40</span></div>
            <div className="sm:hidden"><span>50</span></div>
            <div><span>60</span></div>
            <div className="sm:hidden"><span>70</span></div>
            <div><span>80</span></div>
            <div className="sm:hidden"><span>90</span></div>
            <div><span>100</span></div>
          </div>
        </div>
      </div>
      {/* INFO BOX */}
      <NoteBox1 />
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box sortBtnBox">
          {/* SORT COUNTRY */}
          <button
            className={`${getClassNamesFor('country')} mr-6 ani03s`}
            type="button"
            onClick={() => requestSort('country')}
          >Country</button>
          {/* SORT VALUE */}
          <button
            className={`${getClassNamesFor('value')} mr-6 ani03s`}
            type="button"
            onClick={() => requestSort('value')}
          >Percentage</button>
        </div>
      </div>
    </>
  )
}

export default Figure08 
