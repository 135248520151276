import { useState } from "react";
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import PolicyMenu from "../../components/PolicyMenu";
import TitleButtonLink from '../../components/TitleButtonLink'
// Import Images
import Eeca from '../../images/serbia.svg'
// Import Pdf
import pdf from '../../pdfs/PB-Serbia.pdf'

// Component
const Serbia = () => {

  const pageTitle = 'Policy Brief: Improving complementary feeding in Serbia'

  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  const [showCountryMenu, setShowCountryMenu] = useState(false);

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="POLICY BRIEFS" />
      <article>
        <PageTitle title={ pageTitle } >
          <TitleButtonLink 
            pbUrl = { pdf }
            seeText = { 'Download: '}
            icon = {'icon-pdf'}
            description = { 'Policy Brief - Serbia' }
          />
        </PageTitle>
        <div className="w-full relative pb-12">
          {/* MAP */}
          <img className="w-full" src={Eeca} />
          {/* COUNTRY MENU */}
          <div className="absolute top-0 left-0 w-60">
            <div
              onClick={e => setShowCountryMenu(!showCountryMenu)}
              className="bg-main_blue px-4 py-2 text-white hover:text-sec_blue cursor-pointer ani03s">
              POLICY BRIEFS
              { showCountryMenu ?
               <span className="pl-2 pt-1 icon-close float-right"></span> : 
               <span className="pl-2 pt-1 icon-down float-right"></span> }
            </div>
            { showCountryMenu ? <PolicyMenu active='serbia' /> : <></>}
          </div>
          <button className="
          absolute map_z_info top-2 right-2
          w-6 h-6 rounded-full ani03s
          bg-txt_black_1 text-white text-sm hover:bg-gray
          "
          onClick={() => setShow(true)}
          >
            <span className="relative icon-info top-0.5"></span>
          </button>
        </div>
        <div className="article_content col2">
          <p>Europe and Central Asia Regional (ECAR) Office conducted a regional landscape analysis on trends and predictors of young children’s diets in select countries in the region in 2020. The findings from this analysis are intended to inform the development of a regional framework for complementary feeding that will guide countries in ECAR on policy and programming to accelerate progress toward the prevention of child stunting and obesity. This brief summarizes key findings on the drivers of young children’s diets in BiH as well as major policy recommendations.</p>
          <p className="bg-main_blue p-5 mb-5 text-white text-sm">Complementary feeding refers to the introduction of semi-solid and solid foods to complement breastfeeding and takes place between 6 months and 2 years of age. Nutrient requirements increase significantly during this period. WHO global guidance recommends that children begin consuming foods other than breastmilk at 6 months of age, children are fed a diverse array of food groups, are fed frequently throughout the day, and continue breastfeeding up to 2 years of age</p>
          <h3 className="text-lg text-main_blue">Appropriate complementary feeding is essential to avoid the double-burden of malnutrition in Serbia</h3>
          <p>
            What, when and how babies are fed determines the quality of their diets during the first years of life – with impacts that last a lifetime.  In Serbia about 12% of children suffer from overweight, while child stunting levels are low (3.7%).  According to survey data from 2014, Serbia has the highest timely introduction of complementary foods  in the region at 96.6% and minimum meal frequency at 95.7%.  Serbia also has the second highest minimum dietary diversity in the ECAR region (77%). Serbia has one of the lowest exclusive breastfeeding rates  in the ECAR (i.e. 12.8%), due to high use of breastmilk substitutes (i.e. infant formula).
          </p>
          <p>
            To improve infant and toddler diets we need to consider the range of factors – both within and outside of the household – that influence what, when and how babies eat. These factors include adequate food, services and practices and an enabling environment that support good diets in a more coordinated manner.
          </p>
          <h3 className="text-lg text-main_blue">Key drivers of the complementary feeding in Serbia </h3>
          <p className="font-bold halfPad">Enabling environment</p>
          <p>
            There are no national food-based dietary guidelines in Serbia that focus on improving young children’s diets. The European Association of Pediatric, Gastroenterology and Nutrition, and the University Hospital in Belgrade has adopted the recommendation to start complementary feeding from 4 months of age which is not in line with WHO global standards, which recommends timely complementary feeding at 6 months of age. 
          </p>
          <p className="font-bold halfPad">Adequate Foods</p>
          <p>
            Nutritious foods need to be available, accessible, affordable and desirable to children and their families. There was limited evidence in Serbia   on the availability, accessibility, and affordability of food for young children, based on perspectives from key informant interviews. However, interviews with key informants indicated that availability and access is not perceived as a barrier to achieving improved complementary feeding. More evidence is required to further investigate how availability and access hinder complementary feeding practices in Serbia.
          </p>
          <p className="font-bold halfPad">Adequate Services</p>
          <p>
            Access to quality health and nutrition services, safe water and sanitation services, and effective social protection services for all children can help ensure their families have the support they need to provide their children with nutritious and safe foods. The absence of these services, or poor quality delivery, can instead hinder efforts to improve child diets. In primary health care settings, counseling on  healthy nutrition habits are provided during antenatal care, and  include  information on exclusive breastfeeding, introduction of complementary foods and responsive feeding, as recommended by pediatricians. 
          </p>
          <p>
            With regards to complementary feeding guidance, key messages to families were developed through the  “It’s a hit to be full and fit” campaign, supported by the private sector,    which  provided information on: encouraging children to eat (responsive feeding), exclusive breastfeeding through 6 months, complementary feeding (and not to begin before the 17 weeks [about 4 months] and not after the 26th week), and recommends the “gradual introduction of foods, based on advice from your pediatrician and based on your child’s needs. The guidance on early introduction of complementary foods conflicts with recommendations for exclusive breastfeeding. The guidance states to introduce a teaspoon or two of foods for children to “get” used to new flavors, smells, and mushy foods. The timing for “combination of food” can occur after the initial introduction of several types of vegetables, cereals and fruits, mothers and families are advised to make “combinations” of various foods (for example, rice with carrots, pumpkin or zucchini, potatoes with zucchini and carrots)  It is advised that certain foods are appropriate for children at certain ages, and such food restriction can limit dietary intake and diversity in young children’s diets.
          </p>
          <p>
            Expert committees/working groups- and associations of pediatricians have developed materials (i.e. take home brochures), which  provide feeding recommendations for parents on infants and young children. In addition, after the baby’s birth in the first month of life, through “patronage” services, nurses conduct home visits up to 4-5 times, to provide newborn care, as well as counselling on  breastfeeding. These visits provide an opportunity to see “how well the child is fed and how well the child growing”, and to provide information and counsel families on complementary feeding. One key challenge with providing quality health and nutrition services is that health providers often don’t have time to counsel mothers on complementary feeding due to their heavy workload.   Complementary feeding practices are not monitored through the health monitoring information system (HMIS), limiting the ability of experts and policy makers in the health sector to identify potential problems in real time.  For social protection programming, benefits for families with children are provided, yet use of these benefits in purchasing foods for young children is unknown.
          </p>
          <p className="font-bold halfPad">Adequate Practices</p>
          <p>
            The feeding, care and hygiene behaviors of caregivers support good nutrition for children and their families.  According to a  study with mothers of children, 6-24 months of age (n= 492), 57% of mothers knew that complementary food should be given after 6 months, while 62% of mothers started feeding their babies with complementary food prior to 6 months. There were  no significant differences in these practices according to the level of education and age group, yet a higher percentage of incorrect practices among older mothers (71%).  Most mothers (74%) were advised by healthcare workers regarding timing to introduce complementary foods.  Commercially available complementary foods in Serbia often have high sugar content, low levels of protein and/or lack of information on trans fatty acids or restrictions on child age.
          </p>
          <p>
            Social norms may support harmful nutrition practices    
          </p>
          <p>
            Early introduction of complementary foods are driven by the widespread perception that breastmilk is insufficient, the widely-followed advice of pediatricians who recommend early introduction of food at 4 months and the fear that their child will develop food-related allergies which are associated with food taboos. Mothers often start feeding children boiled rice or rice cereal, alongside milk or breastmilk, and a watery meat-based broth (with no pieces of meat), which is inadequate to meet nutrient needs for healthy growth and development of young children.  Later, children are often fed pieces of meat, vegetables (i.e. carrots) and fruits – like boiled or jarred apple. Stakeholders mentioned that families may lack time to prepare meals for the family, which would allow for greater consumption of processed foods.
          </p>
          <p className="font-bold halfPad">Key recommendations to improve complementary feeding</p>
          <ol className="list-decimal pl-8">
            <li>
              Develop a national food-based dietary guideline that focuses on young children, 6-24 months of age 
              </li>
            <li>
              Monitor and enforce  implementation of The International Code on Marketing of Breastmilk Substitutes and enforcement of World Health Assembly resolution 69.9
              </li>
            <li>
              Develop a social protection policy that targets improvement of young children’s diets  
              </li>
            <li>
              Update guidelines to ensure they are harmonized according to global recommendations for breastfeeding and complementary feeding, which would reinforce timely introduction of complementary foods at 6 months of age, and  address food allergies and food taboos that can restrict children’s dietary intake
              </li>
            <li>
              Strengthen the capacity of health providers to counsel on breastfeeding and  complementary feeding, addressing providers’ time and misperceptions around early introduction of complementary foods  prior to 6 months of age
              </li>
            <li>
              Implement the Baby-Friendly Hospital Initiative throughout the country to protect and promote breastfeeding
              </li>
            <li>
              Integrate infant and young child feeding indicators in the HMIS and monitor progress 
              </li>
            <li>
              Collect up-to-date data on breastfeeding and complementary feeding practices, including infant formula use, through surveys
            </li>
          </ol>
        </div> 
      </article>
      {/* MAP INFO */}
      { show && 
        <div
          onClick={closeModalHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
          <div className="px-8 py-6 bg-white relative max-w-400">
            <div
              onClick={closeModalHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </div>
            These maps are stylized and not to scale, and do not reflect a position by UNICEF on the legal status of any country or territory or the delimitation of any frontiers.
          </div>
        </div>
      }
    </div>
  ) 
}

export default Serbia
