
import Footnote from '../../components/Footnote'

const Table02 = () => {
  return (  
    <div className="table-scroll relative">  
      <table className="tableRightBorder purple purpleCellFirstTd text-left border border-purple xs:text-xs">
        <thead>
          <tr className="text-white bg-purple">
              <th className="text-white bg-purple">Country guidance</th>
              <th>Labelling of infant formula and follow-on formula or commercial foods</th>
              <th>Labelling of  content</th>
              <th>Regulated advertising of infant formula or follow-on formula</th>
          </tr>
        </thead>
        <tbody>

          <tr className="bg-purple_pale">
            <td className="bg-purple_pale">Armenia - law regulating commercial infant food was adopted in 2014,</td>
            <td>Labelling also forbids any text putting commending formula or “approximated to mother’s/human’s milk”, as well as statement in support of breastmilk as a “perfect food for the health growth and development of infants, which protects from diarrhea and diseases.”</td>
            <td>{'Labelling requirements are: information on preparation and use, warning on health hazards, composition and origin of milk or breastmilk substitute, warning on incorrect use (i.e. infant formula for young children (> 6 months of age), not used for infants less than 6 months of age)'}.</td>
            <td>
              <ul className="bullets">
                <li>Prohibits advertising and/or any forms of encouragement (i.e. prizes, awards, gifts, providing samples) for any formula</li>
                <li>Complementary food advertising is allowed if any information provided on complementary feeding encourages exclusive breastfeeding for the first 6 months and continuous breastfeeding until age two.</li>
                <li>The law prohibits the sale and encouragement of infant food by health care professionals, which includes receipt of gift, acceptance or provision of samples of infant food and related products, as well as demonstrations of use of infant food.</li>
                <li>Manufacturers and distributors are prohibited from encouragement via donation of food, providing gifts to health care professionals or institutions , sponsoring events or activities for parents of infant and young children, or family.</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td className="bg-white">BiH Regulations on Infant formula</td>
            <td>Breastmilk substitutes: The product is suitable for infants from birth when they are not breastfed at all or sufficiently and 2)  for follow-on formula the product is suitable only for infants over the age of six months, forms only part of a diversified diet, it is not to be used as a substitute for breast milk during the first six months of life</td>
            <td>.e. energy, protein, carbohydrates, fats, vitamins, mineras, per mL, instructions for preparaton, storage and disposal of the product,</td>
            <td>
              <p>- States appropriate use of the products to be delineated, so as not to discourage breast feeding, <span className="font-bold">and</span> not use ‘humanised’, ‘maternalised’, ‘adapted’, or similar terms.</p>
              <ul className="bullets">
                <li>Include: ‘important notice’statement on the superiority of breastfeeding, use only on the advice of a nutritionist, medical or other professional, and no  pictures of infants, nor other pictures or text that may encourage use of the product;</li> 
                <li>Advertising restricted to publications specializing in baby care and scientific publications and no point-of-sale advertising, giving of samples or any other promotional device to induce sales of infant formula is allowed in BiH.</li>
              </ul>
            </td>
          </tr>

          <tr className="bg-purple_pale">
            <td className="bg-purple_pale">Georgia Law on Protection and Promotion of Breast-Feeding</td>
            <td>
              <p>Breastmilk substitutes: Information on labels are not to belittle the significance of breast-feeding. Labels should indicate in capital letters the following: “WARNING! MOTHER’S MILK IS THE BEST FOOD FOR YOUR BABY” and  “WARNING! PLEASE APPLY TO YOUR PHYSICIAN TILL FEEDING YOUR BABY BY THIS PRODUCT; STRICTLY OBSERVE ALL INDICATIONS RELATED TO USEAND PREPARATION OF FOOD”.</p>
              <p>The labels are prohibited to 1) state that BMS are similar to  breastmilk and 2) have an image of a baby that indicates the merits of bottle-feeding.</p>
            </td>
            <td>Include: food preparation and timing, feeding, inadmissibility of use of remainder of prepared food for baby feeding for the second time; age in figures, = nutrient composition products; ingredients used for preparation of products; conditions for storage and transportation;  expiration date in various climatic and storage conditions; and number of lot and date of manufacturing.</td>
            <td>
              <p>Any advertising of bottle-feeding products, other than additional food, is prohibited, Hanging any advertising, informational and educational material about BMS as well as use or placing of such things that indicate a firm or another mark, name of picture of a manufacturer or distributor of BMS at medical institutions is not allowed.</p>
              <p>Law modified the code on advertising, which states, ‘‘It is prohibited to advertise and propagandize in any form of children artificial-feeding products, except complementary food.”  (* violation of WHA resolution 69.9 <Footnote sup={'3'} text={'WHA 69.9: Ending the inappropriate promotion of foods for infants and young children and called on governments to implement its recommendations, such as ensuring that Code regulations cover all BMS (all milk products intended for children up to the age of 36 months); prohibiting the cross promotion of BMS through the marketing of other products and ending healthcare system conflicts of interest from the baby food industry.'}/>),</p>
            </td>
          </tr>

          <tr>
            <td className="bg-white">Legal frame and implementation of legal regulation on breast-feeding in the Republic of Serbia – Report</td>
            <td>This decree mentions the need to amend the Law on Food Safety with including the provision that the Ministry of Health undertakes the measures for the purpose of encouraging and protection of breast-feeding and promoting of the principles of WHO Codex.</td>
            <td>While no labelling information. The decree states health workers should be informed about their responsibilities which arise from the Codex, including information on benefits and superiority of breast-feeding; nutrition of mother and preparation for breast-feeding and its maintaining; negative effects of partial nutrition through the bottle, to breast-feeding; difficulties of decision change not to breast-feed; correct use of infant formula, where necessary, social and financial implications of using, health risks of inadequate food or feeding method and, especially, health risks from unnecessary or inadequate use of infant formula and other substitutes for breast-milk.</td>
            <td>Amendments on rules of on the purity of dietary product and states that concrete obligations of Ministry of Health and health institutions related to the breast-feeding issues are to be included such as: it is forbidden to the health institutions to promote infant formula or other products protected by the Codex (to list the products) (with the exception of dissemination of information to health workers for the scientific and factual purposes); health institutions should not show the products protected by this regulation, placates or posters related to such products or should not distribute materials of producers or distributers.</td>
          </tr>
          
        </tbody>
      </table>
      
    </div>
  ) 
}
export default Table02
