import React from 'react';
import ReactTooltip from 'react-tooltip';
import SortableData from '../../constants/SortableData'
// Import Content
import NoteBox1 from '../../page_elements/figures/NoteBox1'

// Component
const Figure07 = () => {

  const topValue = 100;
  const charData = React.useMemo( () => [
    { 
      id: 1,
      country: 'Turkmenistan',
      classItem: 'turkmenistan_7',
      value: 93.2,
      perc: (93.2 * 100) / topValue,
    },
    { 
      id: 2,
      country: 'Kazakhstan',
      classItem: 'kazakhstan_7',
      value: 76.9,
      perc: (76.9 * 100) / topValue,
    },
    { 
      id: 3,
      country: 'Kyrgyzstan',
      classItem: 'kyrgyzstan_7',
      value: 76.4,
      perc: (76.4 * 100) / topValue,
    },
    { 
      id: 4,
      country: 'Tajikistan',
      classItem: 'Ttjikistan_7',
      value: 39.9,
      perc: (39.9 * 100) / topValue,
    },
    { 
      id: 5,
      country: 'Armenia',
      classItem: 'armenia_7',
      value: 71.6,
      perc: (71.6 * 100) / topValue,
    },
    { 
      id: 6,
      country: 'Serbia',
      classItem: 'serbia_7',
      value: 95.7,
      perc: (95.7 * 100) / topValue,
    },
    { 
      id: 7,
      country: 'Montenegro',
      classItem: 'montenegro_7',
      value: 90.0,
      perc: (90.0 * 100) / topValue,
    },
    { 
      id: 8,
      country: 'Belrus',
      classItem: 'belrus_7',
      value: 74.2,
      perc: (74.2 * 100) / topValue,
    },
    { 
      id: 9,
      country: 'BiH',
      classItem: 'bih_7',
      value: 72.2,
      perc: (72.2 * 100) / topValue,
    },
    { 
      id: 10,
      country: 'North Macedonia',
      classItem: 'macedonia_7',
      value: 65.2,
      perc: (65.2 * 100) / topValue,
    },
    { 
      id: 11,
      country: 'Ukraine',
      classItem: 'ukraine_7',
      value: 63.7,
      perc: (63.7 * 100) / topValue,
    },
    { 
      id: 12,
      country: 'Albania',
      classItem: 'albania_7',
      value: 51.4,
      perc: (51.4 * 100) / topValue,
    },
    { 
      id: 13,
      country: 'Republic of Moldova',
      classItem: 'moldova_7',
      value: 49.2,
      perc: (49.2 * 100) / topValue,
    }
  ])

  const { items, requestSort, sortConfig } = SortableData(charData);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
    <>
      <div className="barChartBox">
        <div className="barChart">
          {items.map(row => {
            return (
              <div className="chartRow" id={row.id} key={row.id}>
                <div className="labelBox">{ row.country }</div>
                <div className="bars">
                  {/* FIGURE */}
                  
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#3b4395",
                        width: `${row.perc}%`,
                        height: "18px",
                      }}
                      data-tip data-for={row.classItem}
                    ></a>
                    <ReactTooltip id={row.classItem} className='barTooltipe' aria-haspopup='true'>
                      <h3>{row.country}</h3>
                      <p className="tollValue">{row.value}</p>
                    </ReactTooltip>
                  </>

                </div>
              </div>
            )
          })}
        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div className="sm:hidden"><span>10</span></div>
            <div><span>20</span></div>
            <div className="sm:hidden"><span>30</span></div>
            <div><span>40</span></div>
            <div className="sm:hidden"><span>50</span></div>
            <div><span>60</span></div>
            <div className="sm:hidden"><span>70</span></div>
            <div><span>80</span></div>
            <div className="sm:hidden"><span>90</span></div>
            <div><span>100</span></div>
          </div>
        </div>
      </div>
      {/* INFO BOX */}
      <NoteBox1 />
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box sortBtnBox">
          {/* SORT COUNTRY */}
          <button
            className={`${getClassNamesFor('country')} mr-6 ani03s`}
            type="button"
            onClick={() => requestSort('country')}
          >Country</button>
          {/* SORT VALUE */}
          <button
            className={`${getClassNamesFor('value')} mr-6 ani03s`}
            type="button"
            onClick={() => requestSort('value')}
          >Percentage</button>
        </div>
      </div>
    </>
  )
}

export default Figure07 
