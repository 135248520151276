import { useState } from "react";
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import PolicyMenu from "../../components/PolicyMenu";
import Footnote from '../../components/Footnote'
import TitleButtonLink from '../../components/TitleButtonLink'
// Import Images
import Eeca from '../../images/georgia.svg'
// Import Pdf
import pdf from '../../pdfs/PB-Georgia.pdf'

// Component
const Georgia = () => {

  const pageTitle = 'Policy Brief: Improving complementary feeding in Georgia '

  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  const [showCountryMenu, setShowCountryMenu] = useState(false);

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="POLICY BRIEFS" />
      <article>
        <PageTitle title={ pageTitle } >
          <TitleButtonLink 
            pbUrl = { pdf }
            seeText = { 'Download: '}
            icon = {'icon-pdf'}
            description = { 'Policy Brief - Georgia' }
          />
        </PageTitle>
        <div className="w-full relative pb-12">
          {/* MAP */}
          <img className="w-full" src={Eeca} />
          {/* COUNTRY MENU */}
          <div className="absolute top-0 left-0 w-60">
            <div
              onClick={e => setShowCountryMenu(!showCountryMenu)}
              className="bg-main_blue px-4 py-2 text-white hover:text-sec_blue cursor-pointer ani03s">
              POLICY BRIEFS
              { showCountryMenu ?
               <span className="pl-2 pt-1 icon-close float-right"></span> : 
               <span className="pl-2 pt-1 icon-down float-right"></span> }
            </div>
            { showCountryMenu ? <PolicyMenu active='georgia' /> : <></>}
          </div>
          <button className="
          absolute map_z_info top-2 right-2
          w-6 h-6 rounded-full ani03s
          bg-txt_black_1 text-white text-sm hover:bg-gray
          "
          onClick={() => setShow(true)}
          >
            <span className="relative icon-info top-0.5"></span>
          </button>
        </div>
        <div className="article_content col2">
          <p>Europe and Central Asia Regional Office conducted a regional landscape analysis on trends and predictors of young children’s diets in select countries in the region in 2020. The findings from this analysis are intended to inform the development of a regional framework for complementary feeding that will guide countries in ECAR on policy and programming to accelerate progress toward the prevention of child stunting and obesity. This brief summarizes key findings on the drivers of young children’s diets in Georgia as well as major policy recommendations.</p>
          <p className="bg-main_blue p-5 mb-5 text-white text-sm">Complementary feeding refers to the introduction of semi-solid and solid foods to complement breastfeeding and takes place between 6 months and 2 years of age. Nutrient requirements increase significantly during this period. WHO global guidance recommends that children begin consuming foods other than breastmilk at 6 months of age, children are fed a diverse array of food groups, are fed frequently throughout the day, and continue breastfeeding up to 2 years of age</p>
          <h3 className="text-lg text-main_blue">Appropriate complementary feeding is essential to avoid the double-burden of malnutrition in Georgia</h3>
          <p>What, when and how babies are fed determines the quality of their diets during the first years of life – with impacts that last a lifetime. However, the quality of child diets in BiH remains suboptimal. Georgia has 11.3% of children under five years of age who suffer from stunting and 14.3% of children, under five, who are overweight. Anemia is a common health problem in  Georgia, affecting 23% of children below five years of age. In addition, Georgia has  a low exclusive breastfeeding rate (20.4%) and high prevalence of infant formula use. Georgia ranks 8th in the region for timely introduction of complementary foods at 84.5%, yet does not have survey data on complementary feeding indicators (i.e. minimum meal frequency or minimum dietary diversity and minimum acceptable diet).</p>
          <p>To improve infant and toddler diets we need to consider the range of factors – both within and outside of the household – that influence what, when and how babies eat. These factors include adequate food, services and practices and an enabling environment that support good diets in a more coordinated manner.</p>
          <h3 className="text-lg text-main_blue">Key drivers of the complementary feeding in Georgia</h3>
          <p className="font-bold halfPad">Enabling environment</p>
          <p>The Government of Georgia, has a law on “Protection and Promotion of Breastfeeding and Regulation of Artificial Feeding” to prohibit the marketing and distribution of breastmilk substitutes (BMS), which monitors adherence to the International Code for Breastmilk Substitutes  and  regulates   bottle feeding products in alignment with Codex Alimentarius.<Footnote sup={'1'} text={'International food products standard approved by the international commission for food standards'}/> Yet despite the law, there is a high prevalence of marketing of breastmilk substitutes in maternity hospitals and the law is no longer implemented in country, due to a number of violations. This is compounded by high rates of cesarean section rates (47%), lack of provider capacity to counsel on infant and young child feeding (IYCF), and lack of monitoring or enforcement for Code violations. Baby Friendly Hospital Initiative (BFHI) has also languished in the country.</p>
          <p>The Georgia Maternal {'&'} Newborn Health Strategy for 2017-2030   calls for the support for early initiation and exclusive breastfeeding through the Baby-Friendly Hospital Initiative, yet complementary feeding is not mentioned.  The Georgia Guidelines for Child Nutrition  up to 2 years of age provides information on exclusive breastfeeding,  breastfeeding for two years, and the risks and differences between infant formula, breastmilk and animal milks. While brief information is provided on introduction of complementary feeding after 6 months of age and mentions food consistency and food “types” (i.e. transitional food - 6 months to 1 year and family foods from 1 year onwards), stakeholders relayed that this information is outdated and not in accordance with global standards, with regards to meal frequency, dietary diversity and quantity to be fed, according to child age.</p>
          <p className="font-bold halfPad">Adequate Foods</p>
          <p>Nutritious foods need to be available, accessible, affordable and desirable to children and their families. There was limited evidence in Georgia  on the availability, accessibility, and affordability of food for young children, based on perspectives from key informant interviews. However, interviews with key informants indicated that availability and access is not perceived as a barrier to achieving improved complementary feeding. More evidence is required to further investigate how availability and access hinder complementary feeding practices in Georgia.  While some families use breastmilk substitutes/infant formula in the first 6 months of life, which disrupts WHO/UNICEF recommendations for exclusive breastfeeding<Footnote sup={'2'} text={<>WHO defines exclusive breastfeeding as feeding the infant only breastmilk, and no other food or drink, not even water, (including expressed breast milk) for 6 months of life. Infants are allowed to receive drops and syrups (vitamins, minerals and medicines). WHO Fact Sheet: Infant and Young Child Feeding, August 24, 2020 <a href="https://www.who.int/en/news-room/fact-sheets/detail/infant-and-young-child-feeding" target="_blank">https://www.who.int/en/news-room/fact-sheets/detail/infant-and-young-child-feeding</a></>}/>, some families desire to  start complementary feeding earlier than recommended 6 months of age (around 4 months of age), to avoid the added expense of purchasing infant formula.  Commercially available complementary foods (CACFs) are also considered to be expensive by families, in comparison to locally available foods. CACFs in Georgia can be high in sodium, high in  calories and/or have added sugar.</p>
          <p className="font-bold halfPad">Adequate Services</p>
          <p>Access to quality health and nutrition services, safe water and sanitation services, and effective social protection services for all children can help ensure their families have the support they need to provide their children with nutritious and safe foods. The absence of these services, or poor quality delivery, can instead hinder efforts to improve child diets. In Georgia, with regards to complementary feeding counselling, health providers’ (i.e. physicians) often lack time and/or  have incorrect knowledge of on complementary feeding recommendations due to outdated guidance.  This is compounded by providers’ lack of time and long lines at health facilities. Another  key gap is the lack of nutritionists who are deployed in primary health care facilities and are trained to support infant and young child feeding.</p>
          <p>These gaps in numbers of providers and provider capacity  can translate into absence of counseling, lower quality of counseling and  limited prioritization of complementary feeding during health and nutrition services. Social protection services do not target food or interventions for improving young child diets, though food and/or cash vouchers are received by eligible families. In addition,  there is a lack of complementary feeding monitoring indicators within the health monitoring and information system (HMIS), limiting the ability of experts and policy makers in the health sector to identify potential problems in real time.</p>
          <p className="font-bold halfPad">Adequate Practices</p>
          <p>The feeding, care and hygiene behaviors of caregivers support good nutrition for children and their families. Early introduction of foods such as fruits (i.e. apples, peaches, bananas) vegetables (pumpkin, zucchini, potato) or porridge (oats, corn, rice) prior to 6 months of age is a common practice in Georgia, according to key informant interviews, consists of Late introduction of  foods and prolonged breastfeeding are also common practices in some families. In the Soviet era , specific foods were recommended for children at certain ages, (i.e. meat is fed from  7 months, bread from 8 months, yogurt/cottage cheese 7-9 months), and these schedules and beliefs regarding these foods are still held today, which can limit dietary diversity and intake. In addition, cakes/sweets are fed as early as 8-9 months of age.</p>
          <p className="font-bold halfPad">Key recommendations to improve complementary feeding</p>
          <ol className="list-decimal pl-8">
            <li>
              Develop a national food-based dietary guideline that focuses on nutritional needs of young children
            </li>
            <li>
              Update the law on protection and promotion of breastfeeding to include stricter monitoring and enforcement of consequences for Code violations
            </li>
            <li>  
              Develop a social protection policy that targets improvement of young children’s diets
            </li>
            <li>  
              Upgrade existing BFHI guidelines , based on recently  revised  WHO BFHI guidelines
            </li>
            <li>  
              Update guidance in line with global recommendations for breastfeeding and  complementary feeding. Address harms of infant formula, update information on complementary feeding (meal frequency, quantities, and dietary diversity), and address beliefs and norms around ordered introduction of foods and restriction of certain foods for young children
            </li>
            <li>
              Generate targeted communication strategies for parents and families on IYCF, as well as the harms of breastmilk substitutes , and optimal  breastfeeding and complementary feeding. 
            </li>
            <li>
              Update continuous medical education of  health providers to counsel on breastfeeding and  complementary feeding, includes guidance on the harms, promotion and distribution of infant formula in health facilities.
            </li>
            <li>
              Address  providers’ time, lack of incentives and training and shortage of number of health providers
            </li>
            <li>
              Integrate key IYCF Indicators into the HMIS and monitor progress
            </li>
          </ol>
        </div> 
      </article>
      {/* MAP INFO */}
      { show && 
        <div
          onClick={closeModalHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
          <div className="px-8 py-6 bg-white relative max-w-400">
            <div
              onClick={closeModalHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </div>
            These maps are stylized and not to scale, and do not reflect a position by UNICEF on the legal status of any country or territory or the delimitation of any frontiers.
          </div>
        </div>
      }
    </div>
  ) 
}

export default Georgia
