import { useState } from "react";
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import PolicyMenu from "../../components/PolicyMenu";
import Footnote from '../../components/Footnote'
import TitleButtonLink from '../../components/TitleButtonLink'
// Import Images
import Eeca from '../../images/armenia.svg'
// Import Pdf
import pdf from '../../pdfs/PB-Armenia.pdf'

// Component
const Armenia = () => {

  const pageTitle = 'Policy Brief: Improving complementary feeding in Armenia'

  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  const [showCountryMenu, setShowCountryMenu] = useState(false);

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="POLICY BRIEFS" />
      <article>
        <PageTitle title={ pageTitle } >
          <TitleButtonLink 
            pbUrl = { pdf }
            seeText = { 'Download: '}
            icon = {'icon-pdf'}
            description = { 'Policy Brief - Armenia' }
          />
        </PageTitle>
        <div className="w-full relative pb-12">
          {/* MAP */}
          <img className="w-full" src={Eeca} />
          {/* COUNTRY MENU */}
          <div className="absolute top-0 left-0 w-60">
            <div
              onClick={e => setShowCountryMenu(!showCountryMenu)}
              className="bg-main_blue px-4 py-2 text-white hover:text-sec_blue cursor-pointer ani03s">
              POLICY BRIEFS
              { showCountryMenu ?
               <span className="pl-2 pt-1 icon-close float-right"></span> : 
               <span className="pl-2 pt-1 icon-down float-right"></span> }
            </div>
            { showCountryMenu ? <PolicyMenu active='armenia' /> : <></>}
          </div>
          <button className="
          absolute map_z_info top-2 right-2
          w-6 h-6 rounded-full ani03s
          bg-txt_black_1 text-white text-sm hover:bg-gray
          "
          onClick={() => setShow(true)}
          >
            <span className="relative icon-info top-0.5"></span>
          </button>
        </div>
        <div className="article_content col2">
          <p>Europe and Central Asia Regional Office conducted a regional landscape analysis on trends and predictors of young children’s diets in select countries in the region in 2020. The findings from this analysis are intended to inform the development of a regional framework for complementary feeding that will guide countries in ECAR on policy and programming to accelerate progress toward the prevention of child stunting and obesity. This brief summarizes key findings on the drivers of young children’s diets in Armenia as well as major policy recommendations.</p>
          <p className="bg-main_blue p-5 mb-5 text-white text-sm">Complementary feeding refers to the introduction of semi-solid and solid foods to complement breastfeeding and takes place between 6 months and 2 years of age. Nutrient requirements increase significantly during this period. WHO global guidance recommends that children begin consuming foods other than breastmilk at 6 months of age, children are fed a diverse array of food groups, are fed frequently throughout the day, and continue breastfeeding up to 2 years of age</p>
          <h3 className="text-lg text-main_blue">Appropriate complementary feeding is essential to avoid the double-burden of malnutrition in Armenia</h3>
          <p>What, when and how babies are fed determines the quality of their diets during the first years of life – with impacts that last a lifetime.  In Armenia, child stunting has decreased from 18%  in 2005 to 9% in 2015-16, while the proportion of overweight children has increased from 11% to 14 percent in 2015-16.  Moreover, only 45% of infants less than 6 months of age are exclusively breastfed.  The Government of  the Republic of Armenia has recognized breastfeeding practices are inadequate and are hindered by early introduction of liquids and juices prior to 6 months of age and extensive use of formula and cow milk as well as use of bottles and pacifiers.</p>
          <p>This is compounded by issues with violations of the International Code of Marketing of Breastmilk Substitutes, including advertising of infant formula and lack of accountability of institutions and healthcare professionals that may cooperate  with the infant formula distributors or manufacturers.   While 89.8% of children are introduced foods at 6-8 months of age, only 36.3% of children have met minimum dietary diversity requirements, one of the lowest in ECAR. Only 24.5% of children 6-23 months of age have met criteria for a minimum acceptable diet, as Armenia ranks 7th out of 8 countries   in the region with available data.  In addition,  according to a recent study, 32.4 %  of children under five years of age were anemic ({`<`} 110 g/dl)  and 14.7 % had moderate (70-99 g/dl) or severe anemia ({'<'} 70 g/dl), in rural Armenia. <Footnote sup={'1'} text={'Demirchyan A, Petrosyan V, Sargsyan V, Hekimian K.Prevalence and determinants of anaemia among children aged 0-59 months in a rural region of Armenia: a case-control study. Public Health Nutr. 2016 May;19(7):1260-9. doi: 10. Epub 2015 Sep 2.'}/> Lower daily meal frequency  and  lack of dietary intake of meat is associated with child anemia. To improve infant and toddler diets we need to consider the range of factors – both within and outside of the household – that influence what, when and how babies eat. These factors include adequate food, services and practices and an enabling environment that support good diets in a more coordinated manner.</p>
          <h3 className="text-lg text-main_blue">Key drivers of the complementary feeding in Armenia</h3>
          <p className="font-bold halfPad">Enabling environment</p>
          <p>In Armenia, a 2014 law regulating commercial infant food allows complementary food advertising if exclusive breastfeeding and continuous breastfeeding until age two is encouraged. The law prohibits the sale and provision of infant food by health care professionals, manufacturers and distributors, in alignment with World Health Assembly resolution 69.9. Labelling requirements include information on preparation and use, warning on health hazards, composition and origin of milk or breastmilk substitute, warning on incorrect use. The Government of the Republic of Armenia has galvanized efforts to improve breastfeeding practices through a law on marketing of infant foods and a national breastfeeding promotion program, the national nutrition strategy and accompanying 2016-2020 action plan. However, while efforts have largely focused on early, exclusive and continuous breastfeeding, and Baby Friendly Hospital Initiative (BFHI),with inclusion of the 1,000 day period, there is only a singular target for timely introduction of complementary feeding. Existing guidance on complementary feeding needs to be updated in line with global standards to address avoidance of certain foods as well as specified order of foods by child age.</p>
          <p className="font-bold halfPad">Adequate Foods</p>
          <p>Nutritious foods need to be available, accessible, affordable and desirable to children and their families. There was limited evidence in Armenia on the availability, accessibility, and affordability of food for young children. However, interviews with key informants indicated that availability and access is not perceived as a barrier to achieving improved complementary feeding. More evidence is required to further investigate how availability and access hinder complementary feeding practices in Armenia. According to the World Bank Global Consumption data,  the proportion of household expenditures that is spent on food and beverage is 43%.  Of these total expenditures, 19%  is spent on meat and fish. While processed food is available, the high cost of these foods in comparison to locally available foods, translates into a parents’ preference to prepare local, homemade foods rather than purchase commercial foods for children.  However, parents and grandparents have a tendency to “overfeed” children and prefer children to be overweight, which is viewed as “attractive.”</p>
          <p className="font-bold halfPad">Adequate Services</p>
          <p>Access to quality health and nutrition services, safe water and sanitation services, and effective social protection services for all children can help ensure their families have the support they need to provide their children with nutritious and safe foods. The absence of these services, or poor quality delivery, can instead hinder efforts to improve child diets. For example, in Armenia, health providers have insufficient knowledge  on child nutrition and often provide inadequate counseling on complementary feeding.  Updated information on infant and young child nutrition can be updated in existing curricula for pre-and post-graduate educational institutions.  While infant and young child feeding ( IYCF)  training materials for facility-based health providers, content for home visits and guidelines for parents provide information on breastfeeding and complementary feeding, it is difficult for health providers to prioritize complementary feeding counselling due to the shortage of  physicians at primary health care facilities, the aging health provider population, and the lack of incentives for physicians to work in rural provinces.   The country also lacks a cadre of nutritionists trained in provision of infant and young child nutrition counselling, which is a key gap. Complementary feeding practices are not monitored through the health monitoring information system (HMIS), limiting the ability of experts and policy makers in the health sector to identify potential problems in real time.  For social protection services, childcare benefits are provided for children under 2 years old.   Yet, Armenia does not have a designated social protection policy that targets the nutritional needs of infants and young children.</p>
          <p className="font-bold halfPad">Adequate Practices</p>
          <p>The feeding, care and hygiene behaviors of caregivers support good nutrition for children and their families.  In Armenia, mothers often feed rice, buckwheat or oatmeal and a stew of vegetables blended with beans or peas, as “first foods”, according to information collected from key informants. Yet, there is limited knowledge among caregivers and health providers about the importance of a diverse diet for children 6-24 months of age, which is hindered by outdated protocols (i.e. specified order of complementary foods by age, and avoidance of certain foods for young children), beliefs about when and which types of foods can be introduced, and an overreliance on infant formula.</p>
          <p>Social norms can impact children’s diets Limitations on working women’s time can impact the type of foods fed to young children.  Grandparents influence decision-making on food consumption and extended family members often share the responsibility of meal preparation with mothers. While commercially available complementary foods often contain high amounts of sugar, have no age restrictions and/or no information on trans fatty acids on labels, though encouragingly most families, according to key stakeholders prefer to feed homemade foods.</p>
          <p className="font-bold halfPad">Key recommendations to improve complementary feeding</p>
          <ol className="list-decimal pl-8">
            <li>Develop a national food-based dietary guideline for the country, that focuses on nutritional needs of young children</li>
            <li>Monitor and enforce implementation of international and national codes and legislations on marketing of breastmilk substitutes and commercially available complementary foods</li>
            <li>Develop a social protection policy targeting improvement of young children’s diets</li>
            <li>Update manuals and guidelines on IYCF, including addressing beliefs about the harms of infant formula, the timely introduction of complementary foods, and the addressing the types of foods to feed children, including avoidance of certain foods</li>
            <li>Address shortage of numbers of  health providers in the country</li>
            <li>Strengthen the capacity of health providers  to provide  interpersonal counselling on complementary feeding</li>
            <li>Devise and operationalize strategies to promote complementary feeding through classic and innovative channels of communication (digital platforms)</li>
            <li>Integrate key IYCF Indicators into the HMIS and monitor progress</li>
          </ol>
        </div> 
      </article>
      {/* MAP INFO */}
      { show && 
        <div
          onClick={closeModalHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
          <div className="px-8 py-6 bg-white relative max-w-400">
            <div
              onClick={closeModalHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </div>
            These maps are stylized and not to scale, and do not reflect a position by UNICEF on the legal status of any country or territory or the delimitation of any frontiers.
          </div>
        </div>
      }
    </div>
  ) 
}

export default Armenia
