const Home = () => {
  return (
      <div className="h-full flex flex-col justify-center items-center p-4">
        <h1 className="font-bold text-4xl lg:text-3xl md:text-2xl xs:text-xl xxs:text-lg text-white text-center uppercase">Overview of the Complementary</h1>
        <h1 className="font-bold text-4xl lg:text-3xl md:text-2xl xs:text-xl xxs:text-lg text-white text-center uppercase">Feeding and Diets of Young Children</h1>
        <h1 className="font-bold text-4xl lg:text-3xl md:text-2xl xs:text-xl xxs:text-lg text-white text-center uppercase">in Europe and Central Asia REGION</h1>
        <h3 className="text-white bg-txt_black_1 text-2xl md:text-xl xs:text-xs xxs:text-xs px-1 mt-1 inline-block mb-24">Recommendations for Accelerating Progress in Six Core Countries</h3>
      </div>
  )
}
// Export
export default Home

