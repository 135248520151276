import { useState } from 'react'
// Import Components
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
// Import Content
import Table04 from '../../page_elements/tables/Table04'
// Component
const Availability1  = () => {

  const pageTitle = 'Availability, affordability, quality and use of health and nutrition services'
  const table1Desc = 'Reasons for weaknesses in quality of complementary feeding counseling, by ECAR subregion, according to key informant interviews'

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowText(false)
  }

  return (
    <> 
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText = { 'See Text: '}
            icon = {'icon-text-icon'}
            description = { pageTitle }
          />
        }
        {!showTable1 &&
          <TitleButton 
            showHandler = { showTable1Handler }
            seeText = { 'See Table 4: '}
            icon = {'icon-tables-icon'}
            description = { table1Desc }
          />
        }
      </PageTitle> 
      {/* TEXT */}
      { showText && 
        <div className="article_content col2">
          <p>In the ECAR region, counselling on complementary feeding is weak for several reasons outlined in Table 4, based on key informant interviews with stakeholders. Countries faced challenges in rolling out IYCF counseling through primary health care facilities, due to lack of time, a shortage of health providers due to migration/aging, and lack of incentives. Quality of counselling on complementary feeding  was believed to be low at facility and community level, which is reflected in knowledge of providers and families about young children’s diets. While guidelines/guidance on complementary feeding may exist, they were  not always abided by health providers, and none of the health monitoring information systems, collect information on receipt of counseling on complementary feeding.</p>
          <p>In Caucasus countries, such as Armenia, IYCF training materials exist for health providers, and home visits and guidelines for parents provide information on breastfeeding and complementary feeding. For complementary feeding, key messages and content on frequency, quantity, and diversity of complementary foods, sample meal plans, nutritional value and benefits of foods (i.e. iron-rich), importance of responsive feeding, safe storage and preparation of food, and feeding during illness was provided across various modalities.</p>
          <p>While complementary feeding content has been added to  training materials, Caucasus countries face lack of prioritization of complementary feeding in health providers, given the aging population of health providers, the shortage of  physicians to provide child health services at primary health care facilities (i.e. polyclinics or rural ambulatories), and the lack of incentives for physicians to work in rural provinces (i.e. including issues with local transportation and financial reimbursement). The number of pediatricians has declined from 1839 to 934 from 1995-201319.  Currently, 1.62 pediatricians per 1000 children,  0-14 years of age.</p>
          <p className="bg-main_blue p-5 mb-5 text-white text-sm">“We don’t have  data on complementary feeding (on what children and how often they are fed); for more than 10 years  through routine health statistics [system], we need these data to . identify feeding problems We also need survey data  on complementary feeding interventions so  we can further plan interventions, as [currently] we don’t have the whole picture.” Stakeholder, Georgia</p>
          <p>In Central Asia, the health system has not prioritized health worker training and implementation of counseling on complementary feeding was weak or not conducted in primary health care settings. Stakeholders revealed a need to conduct refresher trainings and reinvigorate supportive supervision at community and health facility levels.</p>
        </div>
      }
      {/* TABLE 1 */}
      { showTable1 && 
        <div className="article_content flex flex-col">
          <TableTitle
            title={'Table 4'}
            description={ table1Desc }
          />
          <Table04 />
        </div>
      } 
    </>
  )
}

export default Availability1 
