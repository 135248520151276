
import Footnote from '../../components/Footnote'

const Table02 = () => {
  return (  
    <div className="table-scroll relative">  
      <table className="tableRightBorder purple purpleCellFirstTd text-left border border-purple xs:text-xs">
        <thead>
          <tr className="text-white bg-purple">
              <th className="text-white bg-purple">Country</th>
              <th>Recipe #1</th>
              <th>Recipe #2</th>
              <th>Observations of potential gaps/dietary observations</th>
          </tr>
        </thead>
        <tbody>

          <tr className="bg-purple_pale">
            <td className="bg-purple_pale">Balkan country: Serbia</td>
            <td>
              <ul className="bullets">
                <li>3-4 fresh or frozen broccoli florets</li>
                <li>2 slices of carrots</li>
                <li>A small piece of chicken or turkey</li>
                <li>2 soup spoons of rice and millet</li>
                <li>I small spoon of olive oil</li>
              </ul>
              <p>Boil it for 15 mins, and blend it with a hand blender, cool it for a while.</p>
            </td>
            <td></td>
            <td>
              <ul className="bullets">
                <li>Make sure the porridge falls off the spoon, so the child is not just fed watery broth/porridge</li>
                <li>Ensure enough protein/meat- serve at least two heaped tablespoons of meat, chicken, turkey</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td className="bg-white">Caucasus country - Armenia</td>
            <td>
              <h3>Vegetable soup with meat</h3>
              <ul className="bullets">
                <li>Vegetables</li>
                <li>Half carrot (about 20g.), cabbage (about 20g.), ¼ potato (about 25g.), beets (about 25g.)</li>
                <li>Beef meat – 50g.</li>
                <li>Water – 150 ml</li>
                <li>Butter – 5g.</li>
              </ul>
              <p>How to prepare – wash carefully vegetables and cut them into small pieces. Boil them until ready. Add preliminary prepared (boiled) meat. At the end add butter.</p>
            </td>
            <td>
              <h3>Pure vegetables with poultry</h3>
              <ul className="bullets">
                <li>Vegetable -   half carrot (20g.), half potato (50g), ¼ Bulgarian paper; rice 50g</li>
                <li>Poultry – 50g.</li>
                <li>Butter - 5g.</li>
              </ul>
              <p>How to prepare – wash carefully vegetables and cut them into small pieces. Boil them until ready. Add preliminary prepared (boiled) meat. At the end add butter.</p>
            </td>
            <td>
              <ul className="bullets">
                <li>Stakeholders relayed that often the broth/ watery soup is fed, its important to give a portion of chicken, meat, once per day (at least two heaped tablespoons).</li>
                <li>Remind mothers not to give just the broth that the chicken, meat, or fish was cooked in, but also the flesh.</li>
                <li>Cut, pound, or shred the meat into very small pieces and cook it and feed this to the child</li>
                <li>Mix this with mashed vegetables to be suitable for the child.</li>
                <li>Make sure the soup or porridge is thick enough that it falls off the spoon</li>
              </ul>
            </td>
          </tr>

          <tr className="bg-purple_pale">
            <td className="bg-purple_pale">Caucasus country: Georgia</td>
            <td>
              <h3>Vegetable soup from 6 months:</h3>
              <ul className="bullets">
                <li>2 leaf of celery</li>
                <li>1 piece of onion,</li>
                <li>Small piece of carrot,</li>
                <li>2 leaf of cabbage</li>
                <li>1 piece of garlic</li>
                <li>1 potato</li>
              </ul>
              <p>To boil in water for 30 minutes, then put in blender and add 1 tablespoon of olive oil, don’t add salt or sugar.</p>
            </td>
            <td>
              <h3>Chicken soup  (bulioni) recipe from one year (12 months of age</h3>
              <ul className="bullets">
                <li>One small chicken</li>
                <li>1 carrot</li>
                <li>1 onionv</li>
                <li>50 gr Rice</li>
                <li>2 leaf of celery</li>
                <li>1 piece of garlic </li>
              </ul>
              <p>Put the chicken a large soup pot and cover with cold water and boil. Carrots, garlic celery, onion and rice can be added after 40 minutes. After 1 hour the meal is ready.</p>
            </td>
            <td>
              <ul className="bullets">
                <li>Gaps are watery soup, its important to give a portion of chicken, meat, once per day (at least two heaped tablespoons).</li>
                <li>Make sure the soup or porridge is thick enough that it falls off the spoon</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td className="bg-white">Central Asia country: Tajikistan</td>
            <td>
              <h3>Mashed vegetables – MOH sample meal from 6 months</h3>
              <p>Start with 1-2 teaspoons (i.e. potatoes), daily increase this by 1-2 teaspoons, add 0.5 - 1 teaspoon of vegetable oil, within 2 weeks build up the amount of mashed potatoes to 200 gr, subsequently you need to give the child mix of mashed vegetables (potatoes, carrots, cabbage, pumpkin, turnips, and beets).</p>
            </td>
            <td>
              <p><span className="font-bold">Mashed vegetables</span> 200g.</p>
              <p>(40 teaspoons)</p>
              <p>with 1 teaspoon of vegetable oil (3-5 gr), <span className="font-bold">yolk</span><Footnote sup={'2'} text={'If the child is allergic, do not give the yolk.'}/> start with 1/8 part, give one time and twice per week.</p>
              <p><span className="font-bold">Fruit juice</span> (green apple) start with 1-2 drops and adding daily, build up to 10 ml (2 teaspoons).</p>
              <p><span className="font-bold">Porridge</span> (rice, buckwheat, oatmeal) is boiled in water or boiled and breast milk is added, and given to the child. It is also required to start giving porridge with 1 teaspoon, increased daily - 150 - 200 gr (35-40 tablespoons) within a month + 3 - 5 gr butter</p>
            </td>
            <td>
              <ul className="bullets">
                <li>Work with families to feed the entire egg – that is cooked, rather than just the yolk</li>
                <li>Ensure porridge falls off the spoon</li>
                <li>Feed whole fruits that are mashed rather than fruit juice, which can be added to porridge instead of vegetables</li>
              </ul>
            </td>
          </tr>
          
          
        </tbody>
      </table>
      
    </div>
  ) 
}
export default Table02
