import { useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom'
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
// Import Pages
import Availability1 from '../adequate_s/Availability1'
import Availability2 from '../adequate_s/Availability2'
import Availability3 from '../adequate_s/Availability3'

// Component
const Adequate_s = () => {

  const availability1 = useRef();
  const availability2 = useRef();
  const availability3 = useRef();

  const location = useLocation();

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

  useEffect(() => {
    if(location.hash === '#availability-quality-and-coverage-of-social-protection-services') {
      scrollToRef(availability2);
    }
    if(location.hash === '#availability-quality-affordability-and-use-of-water-and-sanitation-services') {
      scrollToRef(availability3);
    }
  });

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="Adequate Services" />
      <article>
        <section id='availability1' ref={availability1} > 
          <Availability1 />
        </section>
        <section id='availability2' ref={availability2} > 
          <Availability2 />
        </section>
        <section id='availability3' ref={availability3} > 
          <Availability3 />
        </section>
      </article>
    </div>
  ) 
}

export default Adequate_s
