import { useState } from 'react'
// Import Components
import PageTitle from '../../components/PageTitle'
import TitleButton from '../../components/TitleButton'
import TableTitle from '../../components/TableTitle'
// Import Content
import Table05 from '../../page_elements/tables/Table05'
// Component
const Social  = () => {

  const pageTitle = 'Social norms'
  const table1Desc = 'Food taboos, children 6 to 24 months of age, Khatlon province, Tajikistan (Wood, 2018,  Kwabata, et al, McNamara, et al) '

  const [showText, setShowText] = useState(true);
  const [showTable1, setShowTable1] = useState(false);
  function showTextHandler(e) {
    e.preventDefault();
    setShowText(!showText)
    setShowTable1(false)
  }
  function showTable1Handler(e) {
    e.preventDefault();
    setShowTable1(!showTable1)
    setShowText(false)
  }

  return (
    <> 
      <PageTitle title = { pageTitle } >
        {!showText &&
          <TitleButton 
            showHandler = { showTextHandler }
            seeText = { 'See Text: '}
            icon = {'icon-text-icon'}
            description = { pageTitle }
          />
        }
        {!showTable1 &&
          <TitleButton 
            showHandler = { showTable1Handler }
            seeText = { 'See Table 5: '}
            icon = {'icon-tables-icon'}
            description = { table1Desc }
          />
        }
      </PageTitle> 
      {/* TEXT */}
      { showText && 
        <div className="article_content col2">
          <p className="noColumnBreak">{'Food taboos, myths & perceptions regarding specific foods strongly influenced diets in the Balkan and Central Asian countries in the ECAR region due to fear of childhood allergies and food taboos, incorrect knowledge and incorrect beliefs among mothers and health providers, according to data from the literature review and key informant interviews.'}</p>
          <p className="noColumnBreak"><span className="font-bold">In Balkan countries</span>,  most common complementary first foods are- low-allergy foods – fruit juices, cereal porridge, pureed fruit and pureed vegetables.  These foods have a sweetish taste similar to that of breast milk and milk formula, so the baby will accept these first meals more easily. New food is recommended to be fed in small amounts, only one food at a time, every three to seven days. These are fruit juices, fruit purées, cereal gruels, vegetables, meat. Recommended fruits include apples, pears, plums, peaches; of vegetables: carrots. After a period of getting used to the new foods, it is recommended that the infant be offered vegetables purées.</p>
          <p className="noColumnBreak">Feeding practices are driven by the beliefs that breastmilk is insufficient, the adherence to pediatricians’ advice who largely recommend early introduction of food at 4 months, combined with  fear of development of food-related allergies in young children.</p>
          <p className="noColumnBreak"><span className="font-bold">In Central Asia</span>, some mothers introduce foods early before 6 months of age, and it may be a sweet food (i.e. cookie). Meat is a primary feature of the Kazakh diet, as is consumption of processed foods ‘This is a lot of [early introduction of ]food starting from 4-6 months. Mothers may start with  something sweet – like is cookies dipped in tea – which is widely available or a glass bottle – prepared baby foods. Mothers find it easy to use.</p> 
          <p className="noColumnBreak">Post-Soviet Tajikistan faced the threat of severe food insecurity in the mid-1990s, from which emerged food taboos which likely led to greater value in domestically produced foods.  Women (i.e. mothers and mothers-in-law)  relayed that imported food and “foods grown with chemicals [i.e. fertilizer]” are unsafe for human consumption. Imported foods were viewed with distrust, as “impure”, and a cause of poor health.</p>
          <p className="noColumnBreak">Early childhood taboos encompass certain foods restrict the dietary diversity of foods consumed by young children. Restriction or elimination of taboo foods are believed to protect children’s health by lessening the risk of upset stomach, foodborne illness, and delayed language acquisition (see Table 5).</p>
        </div>
      }
      {/* TABLE 1 */}
      { showTable1 && 
        <div className="article_content flex flex-col">
          <TableTitle
            title={'Table 5'}
            description={ table1Desc }
          />
          <Table05 />
        </div>
      } 
    </>
  )
}

export default Social
