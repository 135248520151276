const Table01 = () => {
  return (    
    <table className="allBorders purple purpleCellFirstTd w-full text-left border border-purple xs:text-xs">
      <thead>
        <tr className="text-white bg-purple">
            <th>Indicator Name</th>
            <th>Indicator Definition, according to WHO/UNICEF</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Early initiation of Breastfeeding</td>
          <td>Percentage of newborns put to the breast within one hour of birth</td>
        </tr>

        <tr className="bg-purple_pale">
          <td>Exclusive Breastfeeding, <span className="flex">0-5 months</span>of age</td>
          <td>Percentage of infants 0–5 months of age who are fed exclusively with breast milk</td>
        </tr>

        <tr>
          <td>Introduction of solid, semi-solid or soft foods <span className="flex">6-8 months</span></td>
          <td>Percentage of infants 6–8 months of age who receive solid, semi-solid or soft foods</td>
        </tr>

        <tr className="bg-purple_pale">
          <td>Minimum Meal Frequency (MMF)</td>
          <td>
            <p>Percentage of children 6-23 months of age who received a minimum meal frequency</p>
            <p className="text-purple">Numerator:</p>
            <ul className="bullets">
              <li>Number of breastfed children 6–23 months of age who received solid, semi-solid or soft foods the minimum  number of times or more during the previous day AND the number of non-breastfed children 6–23 months of age who received solid, semi-solid or soft foods or milk feeds the minimum number of times or more during the previous day.</li>
              <li>Minimum is defined as: 2 times solid, semi-solid or soft foods for breastfed infants 6–8 months of age; 3 times solid, semi-solid or soft foods for breastfed children 9–23 months of age; and 4 times solid, semi-solid or soft foods and/or milk feeds for non-breastfed children 6–23 months of age.</li>
            </ul>
            <p className="text-purple">Denominator:</p>
            <ul className="bullets">
              <li>Breastfed children aged 6-23 months AND Non-breastfed children aged 6-23 months</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>Minimum Dietary Diversity (MDD) <span className="flex">6-23 months </span></td>
          <td>
            <p>Percentage of children 6-23 months of age who received a minimum diet diversity</p>
            <p className="text-purple">Numerator:</p>
            <ul className="bullets">
              <li>Number of children 6-23 months of age who received foods from ≥ 5 (out of 8) food groups1 during the previous day</li>
              <li>The eight food groups are: (i) breastmilk; (ii) grains, roots and tubers; (iii) legumes and nuts; (iv) dairy products (infant formula, milk, yogurt, cheese); (v) flesh foods (meat, fish, poultry and liver/organ meats); (vi) eggs; (vii) vitamin-A rich fruits and vegetables; (viii) other fruits and vegetables.</li>
            </ul>
            <p className="text-purple">Denominator:</p>
            <ul className="bullets">
              <li>Children 6-23 months of age.</li>
            </ul>
          </td>
        </tr>

        <tr className="bg-purple_pale">
          <td>Minimum Acceptable Diet (MAD) <span className="flex">6-23 months </span></td>
          <td>
            <p>Percentage of children 6-23 months of age who received a minimum acceptable diet</p>
            <p className="text-purple">Numerator:</p>
            <ul className="bullets">
              <li>Breastfed children 6-23 months of age who had at least the minimum dietary diversity and the minimum meal frequency during the previous day AND Non-breastfed children 6-23 months of age who received at least two milk feedings and had at least the minimum dietary diversity not including milk feeds and the minimum meal frequency during the previous day</li>
            </ul>
            <p className="text-purple">Denominator:</p>
            <ul className="bullets">
              <li>Breastfed children aged 6-23 months AND Non-breastfed children aged 6-23 months</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>Continued Breastfeeding at 1 year <span className="flex">12-15 months</span></td>
          <td>Percentage of children 12–15 months of age who are fed breast milk</td>
        </tr>

        <tr className="bg-purple_pale">
          <td>Continued Breastfeeding at 2 years <span className="flex">20-23 months</span></td>
          <td>Percentage of children 20-23  months of age who are fed breast milk</td>
        </tr>

        <tr className="bg-purple_pale">
          <td className="text-center bg-purple-pale" colspan="2"><span className="text-purple">Infant Feeding Patterns</span></td>
        </tr>

        <tr>
          <td>Breastmilk only, <span className="flex">0-5 months</span></td>
          <td>Percentage of infants 0-5 months of age who received only breastmilk. (Children that were only fed breast milk in the 24 hours before the survey. These infants would not have received water-based liquids, milk based-liquids or food)</td>
        </tr>

        <tr className="bg-purple_pale">
          <td>Breastmilk and  plain water, <span className="flex">0-5 months</span></td>
          <td>Percentage of infants 0-5 months of age who received breastmilk and plain water</td>
        </tr>

        <tr>
          <td>Breastmilk and non-milk liquids, <span className="flex">0-5 months</span></td>
          <td>
            <p>Percentage of infants 0-5 months of age who received breastmilk and non-milk liquids. (Children that were fed breast milk plus plain water in the 24 hours before the survey)</p>
            <ul className="bullets">
              <li>Breastfed children aged 6-23 months AND Non-breastfed children aged 6-23 months</li>
            </ul>
          </td>
        </tr>

        <tr className="bg-purple_pale">
          <td>Breastmilk and other milks/formula, <span className="flex">0-5 months</span></td>
          <td>
            <p>Percentage of infants 0-5 months of age who received breastmilk and formula/other milk.</p>
            <ul className="bullets">
              <li>Children fed breast milk plus non milk liquids (e.g. juice, herbal tea, sweetened water, flavored water, etc.) in the 24 hours before the survey. Children in this category may also have been fed plain water.</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>Breastmilk and complementary foods, <span className="flex">0-5 months</span></td>
          <td>
            <p>Percentage of infants 0-5 months of age who received breastmilk and solid, semi-solid or soft foods</p>
            <ul className="bullets">
              <li>Children fed breast milk plus formula and/or other milk (e.g. infant formula, liquid milk, canned milk, powdered milk, etc.) in the 24 hours before the survey. Children in this category may also have been fed plain water and/or non-milk liquids.</li>
              <li>Children fed breast milk plus solid, semi-solid or soft food from any food group in the 24 hours before the survey. This may include grains, meat, eggs, fruits, vegetables, etc. Children in this category may also have been fed plain water, non-milk liquids and/or other milks/infant formula.</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  ) 
}
export default Table01
