// Import Components
import PageTitle from '../../components/PageTitle'
// Component
const Access = () => {

  const pageTitle = 'Access'

  return (
    <> 
      <PageTitle title={ pageTitle } /> 
      <div className="article_content col2">
        <p className="noColumnBreak">In Balkan and Caucasus countries, access to adequate foods was not seen to affect  young children’s diet, as supermarkets and open markets are available to the public, according to stakeholders’ views. Yet, access was described as an issue for the most vulnerable segments of the population (i.e. poorest).</p>
        <p className="noColumnBreak">In Central Asian countries, while stakeholders also voiced that access to food was not of concern in urban and rural areas, seasonal availability of certain food items (i.e. fruits and vegetables) were seen to affect dietary intake.</p>
        <p className="noColumnBreak">In Central Asian countries, access to foods through local shops and markets remains a vital source of nutritious foods, alongside household food production, which is increasingly important for diversification of young children’s diets in rural areas.   According to recent research, in rural areas, Tajik residents purchased  staples,  such as flour, oil, cereals, and macaroni from  local stores on a daily basis.</p>
      </div>
    </>
  ) 
}

export default Access
