import { useState } from "react";
// Import Components
import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import PolicyMenu from "../../components/PolicyMenu";
import TitleButtonLink from '../../components/TitleButtonLink'
// Import Images
import Eeca from '../../images/tajikistan.svg'
// Import Pdf
import pdf from '../../pdfs/PB-Tajikistan.pdf'

// Component
const Tajikistan = () => {

  const pageTitle = 'Policy Brief: Improving complementary feeding in Tajikistan'

  const [show, setShow] = useState(false);
  const closeModalHandler = () => setShow(false);

  const [showCountryMenu, setShowCountryMenu] = useState(false);

  return (
    <div className="pages">
      <Breadcrumbs breadcrumb="POLICY BRIEFS" />
      <article>
        <PageTitle title={ pageTitle } >
          <TitleButtonLink 
            pbUrl = { pdf }
            seeText = { 'Download: '}
            icon = {'icon-pdf'}
            description = { 'Policy Brief - Tajikistan' }
          />
        </PageTitle>
        <div className="w-full relative pb-12">
          {/* MAP */}
          <img className="w-full" src={Eeca} />
          {/* COUNTRY MENU */}
          <div className="absolute top-0 left-0 w-60">
            <div
              onClick={e => setShowCountryMenu(!showCountryMenu)}
              className="bg-main_blue px-4 py-2 text-white hover:text-sec_blue cursor-pointer ani03s">
              POLICY BRIEFS
              { showCountryMenu ?
               <span className="pl-2 pt-1 icon-close float-right"></span> : 
               <span className="pl-2 pt-1 icon-down float-right"></span> }
            </div>
            { showCountryMenu ? <PolicyMenu active='tajikistan' /> : <></>}
          </div>
          <button className="
          absolute map_z_info top-2 right-2
          w-6 h-6 rounded-full ani03s
          bg-txt_black_1 text-white text-sm hover:bg-gray
          "
          onClick={() => setShow(true)}
          >
            <span className="relative icon-info top-0.5"></span>
          </button>
        </div>
        <div className="article_content col2">
          <p>Europe and Central Asia Regional Office conducted a regional landscape analysis on trends and predictors of young children’s diets in select countries in the region in 2020. The findings from this analysis are intended to inform the development of a regional framework for complementary feeding that will guide countries in ECAR on policy and programming to accelerate progress toward the prevention of child stunting and obesity. This brief summarizes key findings on the drivers of young children’s diets in Tajikistan  as well as major policy recommendations.</p>
          <p className="bg-main_blue p-5 mb-5 text-white text-sm">Complementary feeding refers to the introduction of semi-solid and solid foods to complement breastfeeding and takes place between 6 months and 2 years of age. Nutrient requirements increase significantly during this period. WHO global guidance recommends that children begin consuming foods other than breastmilk at 6 months of age, children are fed a diverse array of food groups, are fed frequently throughout the day, and continue breastfeeding up to 2 years of age</p>
          <h3 className="text-lg text-main_blue">Appropriate complementary feeding is essential to avoid the triple-burden of malnutrition in Tajikistan</h3>
          <p>
            What, when and how babies are fed determines the quality of their diets during the first years of life – with impacts that last a lifetime.  Tajikistan faces the triple burden of malnutrition, inclusive of stunting, overweight/obesity and multiple micronutrient deficiencies. While national prevalence of stunting among Tajik children less than five years of age has steadily declined from 29% to 18% from 2009 to 2017, regional stunting levels vary widely (15-32%).  Child stunting increases markedly from 6-18 months of age.  Moreover, wasting is highest in children less than 6 months of age (14%), while child overweight is low at 3%.  Micronutrient deficiencies, such as vitamin A, iron, and iodine among children, are widespread and anemia {'(Hb < 11 g/dl)'} among children ranges between 24-42% , according to recent surveys. According to a recent study, half of  children  (n=2149) were iron‐deficient (52.4%), 25.4% were anemic , one in three children were vitamin A deficient and half had inadequate iodine intake.  With regards to complementary feeding,  for children 6- 23 months of age, 63.1% of mothers introduced complementary foods by 6-8 months of age, while minimum meal frequency was 39.9% and minimum dietary diversity was only 22.5%, the  which were the lowest in ECAR. For minimum acceptable diet was 9.3%, Tajikistan ranked 8th out of 8 countries, with available data.
          </p>
          <p>
            To improve infant and toddler diets we need to consider the range of factors – both within and outside of the household – that influence what, when and how babies eat. These factors include adequate food, services and practices and an enabling environment that support good diets in a more coordinated manner.
          </p>
          <h3 className="text-lg text-main_blue">Key drivers of the complementary feeding in Tajikistan</h3>
          <p className="font-bold halfPad">Enabling environment</p>
          <p>
            The 2015-2025 ‘Nutrition and Physical Activity Strategy for the Republic of Tajikistan’ aims to “promote and protect the health of the population by improving their nutritional status, diet and physical activity through intersectoral actions.”  The strategy emphasized the importance of breastfeeding promotion and proper complementary feeding practices (i.e. timely introduction of complementary feeding) as critical to child development  and prevention of NCDs. Yet, here are no clear indicators specifically for improving complementary feeding nor for reducing micronutrient deficiencies (i.e. iron, iodine) and child obesity,  which are mentioned in the strategy. Target activities outlined in country strategies related to complementary feeding include a focus on : 1) implementation of national guidelines of feeding  of young children, conduct WHO integrated course for primary health care trainers to counsel on feeding for children less than 2 years of age, and 2))  implementation of WHO program on monitoring the nutritional status of children less than five years of age through mobile communication, and 3) a campaign on advertising of food, especially infant formula and 4) development of materials on nutrition, physical activity and NCD prevention in the curriculum of medical institutions.  The Republic of Tajikistan adopted in August 2019, a law on “supplying  the population with fortified food products”, which provides regulations for the fortification of staples vehicles (including wheat flour and salt) as well as  other food products (i.e. milk, fermented  dairy products, including those targeting child nutrition (i.e. juice, drinks based on fruit and/or vegetable juice). 
          </p>
          <p>
            Tajikistan is part of efforts, led by the WHO European Region to adapt and identify key actions for the WHO/UNICEF/ World Bank Initiative, the Nurturing Care Framework, which aims to enable young children to reach their full development potential, while providing adequate nutrition. Key nutrition actions proposed for young children include breastfeeding, responsive complementary feeding,  improving social interaction and increasing child motor skills for eating and model learning. The framework also emphasizes that industrially processed complementary foods meet standards recommended by the Codex Alimentarius Commission and the Codex Code of Hygiene Practice for Powdered Formulae for Infants and Young Children.
          </p>
          <p className="font-bold halfPad">Adequate Foods</p>
          <p>
            Access to foods through local shops and markets remains a vital source of nutritious foods, alongside household food production, which is increasingly important for diversification of young children’s diets in rural areas of Tajikistan.  According to a World Food Program analyses, food consumption patterns have changed between 2003 and 2017,  with dramatic increases in consumption of eggs and meat by 217 and 106 percent respectively. Vegetable oil and sugar consumption have also significantly increased by 75 percent.  Cost can be a prohibitive factor for increasing intake of nutrient-rich foods. While there are no notable differences in food consumption by household wealth status, the richest households consume more diversified diets with greater quantity and share of vegetables and animal protein (meat, milk/dairy products, eggs). Yet, two thirds of respondents named foods their household could not routinely afford (most commonly meat, followed by fruits and vegetables). Rural villages typically only have small stores with limited offerings, especially during winter and early spring, due to seasonal scarcity and freezing of roads. Remittances, as male heads of households work abroad for seasonal work, are an essential source of cash income (i.e. 31% of households in Tajikistan) and two out of three households were grew their own food crops or kept livestock for household consumption in rural Tajikistan, with women carrying out most of the agricultural activities.
          </p>
          <p className="font-bold halfPad">Adequate Services</p>
          <p>
            Access to quality health and nutrition services, safe water and sanitation services, and effective social protection services for all children can help ensure their families have the support they need to provide their children with nutritious and safe foods. The absence of these services, or poor quality delivery, can instead hinder efforts to improve child diets. In Tajikistan, the health system has not prioritized health worker training and implementation of counseling on complementary feeding is weak or not conducted in primary health care settings. Mothers who report relying on health care providers for feeding advice are less likely to have adequate diversity or minimum acceptable diet (MAD) scores. Complementary feeding practices are not monitored through the health monitoring information system (HMIS), limiting the ability of experts and policy makers in the health sector to identify potential problems in real time.  Regarding social protection services, social safety nets can be used to increase access to food for young children, yet the current benefits are insufficient for families to afford nutritious foods, and it is not known if those funds are spent on foods for young children
          </p>
          <p className="font-bold halfPad">Adequate Practices</p>
          <p>
            The feeding, care and hygiene behaviors of caregivers support good nutrition for children and their families. Tajik households often provide one main meal  of stew or soup including starches, vegetables, and meat (as available), with bread and tea. Therefore, infants and young children are often introduced gradually to aspects of the household diet, with early foods limited to broth, followed by mashed starches (i.e. potatoes). Sweetened tea, bread dipped in tea and/ or cookies are commonly consumed throughout the day by family members and it has been noted that infants may be offered these items as first foods. 
          </p>
          <p>
            Complex household dynamics exist around food purchasing, food preparation, and how and what young children are fed in Tajikistan.  In rural areas, male out-migration for employment (i.e. 25-40% to Russia) has increased the burden of responsibilities for women. This shifting macroeconomic forces have changed support structures, as with more absent fathers, women have increased burden to carry out agricultural activities and have  less time to care for family, which may affect IYCF  practices – i.e. women are less able to exclusively breastfeed, feed frequently enough and ensure that children receive a diverse diet. The fall of the Soviet Union catalyzed women’s entry into larger scale, waged food production when a sudden drop in employment triggered men migrating for work . In the absence of men, women filled many traditionally masculine occupations- as three-quarters of women are involved in waged agricultural labor . Food systems changed, as women used their knowledge of low-input growing methods-  yet received limited access to fertilizers, pesticides, improved seeds, high-quality irrigation - usually targeted towards male farmers. 
          </p>
          <p>
            Tajik women typically move to their husband's village and family home, following marriage,  where diets are often shaped by multigenerational hierarchical  dynamics within the household, which are affected by seasonal agrarian economics. In one study, rural household size ranged from 4 to 21 members with married sons, daughters‐in‐law, and children. Sixty‐nine percent of mothers reported living in households headed by their fathers‐in‐law. While most mothers (65%) reported being the prime decision-maker regarding their child's feeding. In 25% of homes, mothers‐in‐law decided how children were fed, and in 10% of families as husbands or fathers‐in‐law, had roles. Fathers‐in‐law were the most common person to purchase food, either alone or with other family members (i.e., mothers‐in‐law or adult sons) and mothers‐in‐law were the most frequent decision maker regarding household food purchases and meal planning. Formative research reveals that during meal observations, mothers prepared meals half of the time, while mother-in-law, sister or sister in law’s prepared meals for the remaining observations.  Food was not prepared separately half of the time. 
          </p>
          <p className="font-bold halfPad">Social norms can reduce child dietary intake</p>
          <p>Post-Soviet Tajikistan faced the threat of severe food insecurity in the mid-1990s, from which emerged food taboos which likely led to greater value in domestically produced foods.  Women (i.e. mothers and mothers-in-law)  relayed that imported food and “foods grown with chemicals [i.e. fertilizer]” are unsafe for human consumption. Imported foods were viewed with distrust, as “impure”, and a cause of poor health.  Foods with additives and foods “grown with chemicals” were relayed by women to be avoided by all family members, regardless of age or gender. While fruits and vegetables were perceived as “healthy”, findings reveal that women grappled with consumption of  “fruits and vegetables [which] have a lot of fertilizer and chemicals.” Early childhood taboos encompass certain foods restrict the dietary diversity of foods consumed by young children. Restriction or elimination of taboo foods are believed to protect children’s health by lessening the risk of upset stomach, foodborne illness, and delayed language acquisition. Several studies revealed that family members believe that infants and young children, 6-24 months of age,  were highly sensitive to gastroenteric upset based on the belief that they cannot digest foods as effectively as adults-  the most common reason for excluding certain foods from young children’s diets.</p>
          <p className="font-bold halfPad">Key recommendations to improve complementary feeding</p>
          <ol className="list-decimal pl-8">
            <li>
              Develop a national food-based dietary guideline that focuses on improving diets young children, 6-24 months of age 
            </li>
            <li>
              Develop a social protection policy that targets improvement of young children’s diets  
            </li>
            <li>
              Strengthen health providers’ capacity  to provide quality interpersonal counselling on complementary feeding through refresher trainings and supportive supervision 
            </li>
            <li>
              Address mothers and influential family members about the “why” of a high‐quality, diverse diet, including food taboos, and on “how” to economically diversify diets, by increasing use of nonmeat proteins and Vitamin A‐rich vegetables, as well as food storage or preservation methods to overcome seasonal scarcity
            </li>
            <li>
              Address and dispel any beliefs concerning childhood taboos and food restrictions, during complementary feeding counseling and through targeted communication strategies to mothers and families
            </li>
            <li>
              Integrate key IYCF Indicators into the HMIS and monitor progress
            </li>
          </ol>
        </div> 
      </article>
      {/* MAP INFO */}
      { show && 
        <div
          onClick={closeModalHandler}
          style={{ opacity: show ? 1 : 0 }}
          className="fixed h-full w-full inset-0 bg-gray bg-opacity-75 flex flex-col justify-center items-center p-10 ani03s">
          <div className="px-8 py-6 bg-white relative max-w-400">
            <div
              onClick={closeModalHandler}
              className="icon-close absolute top-1 right-1 cursor-pointer">
            </div>
            These maps are stylized and not to scale, and do not reflect a position by UNICEF on the legal status of any country or territory or the delimitation of any frontiers.
          </div>
        </div>
      }
    </div>
  ) 
}

export default Tajikistan
