import './App.css';
// IMPORT ROUTER
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// IMPORT BACKGROUND
import Background from './images/back.jpg'
// IMPORT COMPONENTES
import Header from './components/Header'
import Social from './components/Social'
import GoTop from './components/GoTop'
// IMPORT PAGES
import Home from './pages/Home'
import Findings from './pages/findings/Findings'
import Executive from './pages/executive/Executive'
import Determinants from './pages/determinants/Determinants'
import Adequate_s from './pages/adequate_s/Adequate_s'
import Adequate_p from './pages/adequate_p/Adequate_p'
import Enabling from './pages/enabling/Enabling'
import Key from './pages/key/Key'
import Policy from './pages/policy/Policy'
import Armenia from './pages/policy/Armenia'
import Bosnia from './pages/policy/Bosnia'
import Georgia from './pages/policy/Georgia'
import Kazakhstan from './pages/policy/Kazakhstan'
import Serbia from './pages/policy/Serbia'
import Tajikistan from './pages/policy/Tajikistan'

// APP
function App() {
  
  return (
    <Router>
      <div 
        className="
          w-screen h-screen
          flex flex-col
          bg-no-repeat bg-cover bg-right-top"
        style={{backgroundImage: 'url(' + Background + ')'}}
      >
        <Header />
        <div className={`h-full w-full mt-24 md:mt-20 sm:mt-16`}>
          <Switch>
            {/* HOME */}
            <Route path="/" exact>
              <Home />
            </Route>
            {/* HOME */}
            <Route path="/findings">
              <Findings />
              <GoTop />
            </Route>
            {/* EXECUTIVE */}
            <Route path="/executive-summary">
              <Executive />
              <GoTop />
            </Route>
            {/* DETERMINANTS */}
            <Route path="/determinants-and-drivers-of-adequate-diets">
              <Determinants />
              <GoTop />
            </Route>
            {/* ADEQUATE SERVICES */}
            <Route path="/adequate-services">
              <Adequate_s />
              <GoTop />
            </Route>
            {/* ADEQUATE PRACTICES */}
            <Route path="/adequate-practices">
              <Adequate_p />
              <GoTop />
            </Route>
            {/* ENABLING ENVIRONMENT */}
            <Route path="/enabling-environment">
              <Enabling />
              <GoTop />
            </Route>
            {/* KEY RECOMMENDATIONS */}
            <Route path="/key-recommendations">
              <Key />
              <GoTop />
            </Route>
            {/* POLICY BRIEFS */}
            <Route path="/policy-briefs">
              <Policy />
            </Route>
            {/* POLICY BRIEFS - ARMENIA */}
            <Route path="/policy-briefs-armenia">
              <Armenia />
              <GoTop />
            </Route>
            {/* POLICY BRIEFS - BOSNIA */}
            <Route path="/policy-briefs-bosnia-herzegovina">
              <Bosnia />
              <GoTop />
            </Route>
            {/* POLICY BRIEFS - GEORGIA */}
            <Route path="/policy-briefs-georgia">
              <Georgia />
              <GoTop />
            </Route>
            {/* POLICY BRIEFS - KAZAKHSTAN */}
            <Route path="/policy-briefs-kazakhstan">
              <Kazakhstan />
              <GoTop />
            </Route>
            {/* POLICY BRIEFS - SERBIA */}
            <Route path="/policy-briefs-serbia">
              <Serbia />
              <GoTop />
            </Route>
            {/* POLICY BRIEFS - TAJIKISTAN */}
            <Route path="/policy-briefs-tajikistan">
              <Tajikistan />
              <GoTop />
            </Route>
          </Switch>
        </div>
        <Social />
      </div>
    </Router>
  );
}

export default App;
