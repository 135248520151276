import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import SortableData from '../../constants/SortableData'

// Component
const Figure10 = () => {

  const topValue = 80;

  const [showArmenia, setShowArmenia] = useState(true);
  const handleClickArmenia = () => setShowArmenia(!showArmenia)
  const [showBiH, setShowBiH] = useState(true);
  const handleClickBiH = () => setShowBiH(!showBiH)
  const [showGeorgia, setShowGeorgia] = useState(true);
  const handleClickGeorgia = () => setShowGeorgia(!showGeorgia)
  const [showKazakstan, setShowKazakstan] = useState(true);
  const handleClickKazakstan = () => setShowKazakstan(!showKazakstan)
  const [showSerbia, setShowSerbia] = useState(true);
  const handleClicSerbia = () => setShowSerbia(!showSerbia)
  const [showTajikistan, setShowTajikistan] = useState(true);
  const handleClickTajikistan = () => setShowTajikistan(!showTajikistan)


  const [showCat1, setShowCat1] = useState(true);
  const handleClickCat1 = () => setShowCat1(!showCat1)
  const [showCat2, setShowCat2] = useState(true);
  const handleClickCat2 = () => setShowCat2(!showCat2)
  const [showCat3, setShowCat3] = useState(true);
  const handleClickCat3 = () => setShowCat3(!showCat3)
  const [showCat4, setShowCat4] = useState(true);
  const handleClickCat4 = () => setShowCat4(!showCat4)
  const [showCat5, setShowCat5] = useState(true);
  const handleClickCat5 = () => setShowCat5(!showCat5)


  const cat1Label = 'Breastmilk only'
  const cat2Label = 'Breastmilk and water'
  const cat3Label = 'Breastmilk and milk'
  const cat4Label = 'Breastmilk and other milks-formula'
  const cat5Label = 'Breastmilk and complementary foods'

  return (
    <>
      <div className="barChartBox">
        <div className="barChart">
          {/* CAT 1 */}
          {showCat1 && 
            <div className="chartRow pb-2">
              <div className="labelBox flex items-center">
                <div className="w-full">{ cat1Label }</div>
              </div>
              <div className="bars">
                {/* ARMENIA */}
                {showArmenia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#3b4395",
                        width: (14.8 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat1_armenia'
                    ></a>
                    <ReactTooltip id='cat1_armenia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat1Label }</h3>
                      <p>Armenia</p>
                      <p className="tollValue">14.8</p>
                    </ReactTooltip>
                  </>
                }
                {/* BIH */}
                {showBiH && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#f36c21",
                        width: (29.9 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat1_bih'
                    ></a>
                    <ReactTooltip id='cat1_bih' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat1Label }</h3>
                      <p>BiH</p>
                      <p className="tollValue">29.9</p>
                    </ReactTooltip>
                  </>
                }
                {/* GEORGIA */}
                {showGeorgia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#808080",
                        width: (17.9 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat1_georgia'
                    ></a>
                    <ReactTooltip id='cat1_georgia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat1Label }</h3>
                      <p>Georgia</p>
                      <p className="tollValue">17.9</p>
                    </ReactTooltip>
                  </>
                }
                {/* KAZAKSTAN */}
                {showKazakstan && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#ffc20e",
                        width: (69.7 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat1_kazakstan'
                    ></a>
                    <ReactTooltip id='cat1_kazakstan' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat1Label }</h3>
                      <p>Kazakstan</p>
                      <p className="tollValue">69.7</p>
                    </ReactTooltip>
                  </>
                }
                {/* SERBIA */}
                {showSerbia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#0b709a",
                        width: (22.9 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat1_serbia'
                    ></a>
                    <ReactTooltip id='cat1_serbia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat1Label }</h3>
                      <p>Serbia</p>
                      <p className="tollValue">22.9</p>
                    </ReactTooltip>
                  </>
                }
                {/* TAJIKISTAN */}
                {showTajikistan && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#87b926",
                        width: (62.1 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat1_tajikistan'
                    ></a>
                    <ReactTooltip id='cat1_tajikistan' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat1Label }</h3>
                      <p>Tajikistan</p>
                      <p className="tollValue">62.1</p>
                    </ReactTooltip>
                  </>
                }
              </div>
            </div>
          }
          {/* CAT 2 */}
          {showCat2 && 
            <div className="chartRow pb-2">
              <div className="labelBox flex items-center">
                <div className="w-full">{ cat2Label }</div>
              </div>
              <div className="bars">
                {/* ARMENIA */}
                {showArmenia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#3b4395",
                        width: (20.6 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat2_armenia'
                    ></a>
                    <ReactTooltip id='cat2_armenia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat2Label }</h3>
                      <p>Armenia</p>
                      <p className="tollValue">20.6</p>
                    </ReactTooltip>
                  </>
                }
                {/* BIH */}
                {showBiH && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#f36c21",
                        width: (12.4 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat2_bih'
                    ></a>
                    <ReactTooltip id='cat2_bih' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat2Label }</h3>
                      <p>BiH</p>
                      <p className="tollValue">12.4</p>
                    </ReactTooltip>
                  </>
                }
                {/* GEORGIA */}
                {showGeorgia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#808080",
                        width: (9.4 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat2_georgia'
                    ></a>
                    <ReactTooltip id='cat2_georgia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat2Label }</h3>
                      <p>Georgia</p>
                      <p className="tollValue">9.4</p>
                    </ReactTooltip>
                  </>
                }
                {/* KAZAKSTAN */}
                {showKazakstan && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#ffc20e",
                        width: (23.9 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat2_kazakstan'
                    ></a>
                    <ReactTooltip id='cat2_kazakstan' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat2Label }</h3>
                      <p>Kazakstan</p>
                      <p className="tollValue">23.9</p>
                    </ReactTooltip>
                  </>
                }
                {/* SERBIA */}
                {showSerbia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#0b709a",
                        width: (22.4 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat2_serbia'
                    ></a>
                    <ReactTooltip id='cat2_serbia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat2Label }</h3>
                      <p>Serbia</p>
                      <p className="tollValue">22.4</p>
                    </ReactTooltip>
                  </>
                }
                {/* TAJIKISTAN */}
                {showTajikistan && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#87b926",
                        width: (21.7 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat2_tajikistan'
                    ></a>
                    <ReactTooltip id='cat2_tajikistan' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat2Label }</h3>
                      <p>Tajikistan</p>
                      <p className="tollValue">21.7</p>
                    </ReactTooltip>
                  </>
                }
              </div>
            </div>
          }
          {/* CAT 3 */}
          {showCat3 && 
            <div className="chartRow pb-2">
              <div className="labelBox flex items-center">
                <div className="w-full">{ cat3Label }</div>
              </div>
              <div className="bars">
                {/* ARMENIA */}
                {showArmenia && 
                  <>
                    <div
                      className="bar"
                      style={{
                        width: (0 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                    ></div>
                  </>
                }
                {/* BIH */}
                {showBiH && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#f36c21",
                        width: (16.1 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat3_bih'
                    ></a>
                    <ReactTooltip id='cat3_bih' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat3Label }</h3>
                      <p>BiH</p>
                      <p className="tollValue">16.1</p>
                    </ReactTooltip>
                  </>
                }
                {/* GEORGIA */}
                {showGeorgia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#808080",
                        width: (7.5 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat3_georgia'
                    ></a>
                    <ReactTooltip id='cat3_georgia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat3Label }</h3>
                      <p>Georgia</p>
                      <p className="tollValue">7.5</p>
                    </ReactTooltip>
                  </>
                }
                {/* KAZAKSTAN */}
                {showKazakstan && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#ffc20e",
                        width: (0.4 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat3_kazakstan'
                    ></a>
                    <ReactTooltip id='cat3_kazakstan' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat3Label }</h3>
                      <p>Kazakstan</p>
                      <p className="tollValue">0.4</p>
                    </ReactTooltip>
                  </>
                }
                {/* SERBIA */}
                {showSerbia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#0b709a",
                        width: (4.6 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat3_serbia'
                    ></a>
                    <ReactTooltip id='cat3_serbia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat3Label }</h3>
                      <p>Serbia</p>
                      <p className="tollValue">4.6</p>
                    </ReactTooltip>
                  </>
                }
                {/* TAJIKISTAN */}
                {showTajikistan && 
                  <>
                  <div
                    className="bar"
                    style={{
                      width: (0 * 100) / topValue + `%`,
                      height: "12px",
                    }}
                  ></div>
                </>
                }
              </div>
            </div>
          }
          {/* CAT 4 */}
          {showCat4 && 
            <div className="chartRow pb-2">
              <div className="labelBox flex items-center">
                <div className="w-full">{ cat4Label }</div>
              </div>
              <div className="bars">
                {/* ARMENIA */}
                {showArmenia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#3b4395",
                        width: (4 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat4_armenia'
                    ></a>
                    <ReactTooltip id='cat4_armenia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat4Label }</h3>
                      <p>Armenia</p>
                      <p className="tollValue">4</p>
                    </ReactTooltip>
                  </>
                }
                {/* BIH */}
                {showBiH && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#f36c21",
                        width: (22 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat4_bih'
                    ></a>
                    <ReactTooltip id='cat4_bih' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat4Label }</h3>
                      <p>BiH</p>
                      <p className="tollValue">22</p>
                    </ReactTooltip>
                  </>
                }
                {/* GEORGIA */}
                {showGeorgia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#808080",
                        width: (47.4 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat4_georgia'
                    ></a>
                    <ReactTooltip id='cat4_georgia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat4Label }</h3>
                      <p>Georgia</p>
                      <p className="tollValue">47.4</p>
                    </ReactTooltip>
                  </>
                }
                {/* KAZAKSTAN */}
                {showKazakstan && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#ffc20e",
                        width: (2.9 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat4_kazakstan'
                    ></a>
                    <ReactTooltip id='cat4_kazakstan' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat4Label }</h3>
                      <p>Kazakstan</p>
                      <p className="tollValue">2.9</p>
                    </ReactTooltip>
                  </>
                }
                {/* SERBIA */}
                {showSerbia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#0b709a",
                        width: (44 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat4_serbia'
                    ></a>
                    <ReactTooltip id='cat4_serbia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat4Label }</h3>
                      <p>Serbia</p>
                      <p className="tollValue">44</p>
                    </ReactTooltip>
                  </>
                }
                {/* TAJIKISTAN */}
                {showTajikistan && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#87b926",
                        width: (8.4 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat4_tajikistan'
                    ></a>
                    <ReactTooltip id='cat4_tajikistan' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat4Label }</h3>
                      <p>Tajikistan</p>
                      <p className="tollValue">8.4</p>
                    </ReactTooltip>
                  </>
                }
              </div>
            </div>
          }
          {/* CAT 5 */}
          {showCat5 && 
            <div className="chartRow pb-2">
              <div className="labelBox flex items-center">
                <div className="w-full">{ cat5Label }</div>
              </div>
              <div className="bars">
                {/* ARMENIA */}
                {showArmenia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#3b4395",
                        width: (35.5 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat5_armenia'
                    ></a>
                    <ReactTooltip id='cat5_armenia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat5Label }</h3>
                      <p>Armenia</p>
                      <p className="tollValue">35.5</p>
                    </ReactTooltip>
                  </>
                }
                {/* BIH */}
                {showBiH && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#f36c21",
                        width: (7.2 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat5_bih'
                    ></a>
                    <ReactTooltip id='cat5_bih' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat5Label }</h3>
                      <p>BiH</p>
                      <p className="tollValue">7.2</p>
                    </ReactTooltip>
                  </>
                }
                {/* GEORGIA */}
                {showGeorgia && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#808080",
                        width: (5.3 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat5_georgia'
                    ></a>
                    <ReactTooltip id='cat5_georgia' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat5Label }</h3>
                      <p>Georgia</p>
                      <p className="tollValue">5.3</p>
                    </ReactTooltip>
                  </>
                }
                {/* KAZAKSTAN */}
                {showKazakstan && 
                  <>
                    <div
                      className="bar"
                      style={{
                        width: (0 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                    ></div>
                  </>
                }
                {/* SERBIA */}
                {showSerbia && 
                  <>
                  <div
                    className="bar"
                    style={{
                      width: (0 * 100) / topValue + `%`,
                      height: "12px",
                    }}
                  ></div>
                </>
                }
                {/* TAJIKISTAN */}
                {showTajikistan && 
                  <>
                    <a
                      className="bar"
                      style={{
                        backgroundColor: "#87b926",
                        width: (1.8 * 100) / topValue + `%`,
                        height: "12px",
                      }}
                      data-tip data-for='cat5_tajikistan'
                    ></a>
                    <ReactTooltip id='cat5_tajikistan' className='barTooltipe' aria-haspopup='true'>
                      <h3>{ cat5Label }</h3>
                      <p>Tajikistan</p>
                      <p className="tollValue">1.8</p>
                    </ReactTooltip>
                  </>
                }
              </div>
            </div>
          }
        </div>
        {/* BACK LINES */}
        <div className="lines" aria-hidden="true">
          <div className="labelBox solidLine"><span>0</span></div>
          <div className="dotLines">
            <div><span>10</span></div>
            <div><span>20</span></div>
            <div><span>30</span></div>
            <div><span>40</span></div>
            <div><span>50</span></div>
            <div><span>60</span></div>
            <div><span>70</span></div>
            <div><span>80</span></div>
          </div>
        </div>
      </div>
      {/* LEGEND */}
      <div className="
        flex flex-nowrap justify-start
        pt-2 text-sm pb-1">
          <div className="text-purple">Legend:</div>
          <div className="w-3 h-3 bg-purple ml-2 mr-1 mt-1 "></div>
          <div>Arménia</div>
          <div className="w-3 h-3 bg-orange ml-3 mr-1 mt-1 "></div>
          <div>BiH</div>
          <div className="w-3 h-3 bg-gray ml-3 mr-1 mt-1 "></div>
          <div>Georgia</div>
          <div className="w-3 h-3 bg-yellow ml-2 mr-1 mt-1"></div>
          <div>Kazakstan</div>
          <div className="w-3 h-3 bg-sec_blue ml-2 mr-1 mt-1 "></div>
          <div>Serbia</div>
          <div className="w-3 h-3 bg-green ml-2 mr-1 mt-1 "></div>
          <div>Tajikistan</div>
      </div>
      {/* SORT CHECKBOXES */}
      <div className="flex text-main_blue text-sm">Select to sort</div>
      <div className="sortBox">
        {/* LEFT BOX */}
        <div className="left_box sortBtnBox">
          {/* CHECKBOX CAT 1 */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ cat1Label }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickCat1} defaultChecked={showCat1} type="checkbox" id="cat1"/>
                <label htmlFor="cat1"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX CAT 2 */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ cat2Label }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickCat2} defaultChecked={showCat2} type="checkbox" id="cat2"/>
                <label htmlFor="cat2"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX CAT 3 */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ cat3Label }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickCat3} defaultChecked={showCat3} type="checkbox" id="cat3"/>
                <label htmlFor="cat3"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX CAT 4 */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ cat4Label }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickCat4} defaultChecked={showCat4} type="checkbox" id="cat4"/>
                <label htmlFor="cat4"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX CAT 5 */}
          <div className="flex items-center pr-6">
            <div className="pr-2">{ cat5Label }</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickCat5} defaultChecked={showCat5} type="checkbox" id="cat5"/>
                <label htmlFor="cat5"></label>
              </div>
            </div>
          </div>
          
        </div>
        {/* RIGHT BOX */}
        <div className="right_box">
          {/* CHECKBOX ARMENIA */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Armenia</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickArmenia} defaultChecked={showArmenia} type="checkbox" id="armenia"/>
                <label htmlFor="armenia"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX BIH */}
          <div className="flex items-center pr-6">
            <div className="pr-2">BiH</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickBiH} defaultChecked={showBiH} type="checkbox" id="bih"/>
                <label htmlFor="bih"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX GEORGIA */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Georgia</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickGeorgia} defaultChecked={showGeorgia} type="checkbox" id="georgia"/>
                <label htmlFor="georgia"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX Kazakstan */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Kazakstan</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickKazakstan} defaultChecked={showKazakstan} type="checkbox" id="kazakstan"/>
                <label htmlFor="kazakstan"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX Serbia */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Serbia</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClicSerbia} defaultChecked={showSerbia} type="checkbox" id="serbia"/>
                <label htmlFor="serbia"></label>
              </div>
            </div>
          </div>
          {/* CHECKBOX Tajikistan */}
          <div className="flex items-center pr-6">
            <div className="pr-2">Tajikistan</div>
            <div className="round_checkbox_box">
              <div className="center">
                  <input onClick={handleClickTajikistan} defaultChecked={showTajikistan} type="checkbox" id="tajikistan"/>
                <label htmlFor="tajikistan"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Figure10 
