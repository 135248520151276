import React from 'react';
import Chart from "react-google-charts";
// Import Content
import NoteBox1 from '../../page_elements/figures/NoteBox1'
// Component
const Figure11 = () => {


  return (
    <>
      <Chart
        width={'600px'}
        height={'400px'}
        chartType="Line"
        loader={<div>Loading Chart</div>}
        data={[
          [
            '',
            'MAD',
            'MDD',
            'MMF',
            'Stunting',
            'Overweight',
          ],
          ['2010', 31.7, 49.2, 66.7, 20.9, 16.5],
          ['2016', 24.5, 36.3, 71.6, 9.4, 13.7],
        ]}
        options={{
          vAxis: {
            minValue: 0, 
            maxValue: 100,
          },
          pointSize: '30',
        }}
        rootProps={{ 'data-testid': '1' }}
      />
      {/* INFO BOX */}
      <NoteBox1 />
    </>
  )
}

export default Figure11 
