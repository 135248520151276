// Import Components
import PageTitle from '../../components/PageTitle'
// Component
const Availability = () => {

  const pageTitle = 'Availability'

  return (
    <> 
      <PageTitle title={ pageTitle } /> 
      <div className="article_content col2">
        <p className="noColumnBreak">Availability was not viewed as a hinderance to adequate diets of young children during the period of complementary feeding in Balkans and Caucasus countries, according to information collected from key informant interviews. In Balkan countries, local foods, such as fruits and vegetables are available, and stakeholders relayed that most  commercially produced foods  tailored for children are fortified, including cereals, processed foods,  and yogurt. According to key stakeholders from four countries, the problem lies in primarily in families’ knowledge of the type of foods that should be fed to young children.</p>
        <p className="noColumnBreak">In Caucasus countries, most families are reported prepare food  at home. Stakeholders relayed that neither food availability nor access were issues in the country.</p>
        <p className="noColumnBreak">In Central Asia, complementary feeding practices are hindered by seasonal fluctuations in agriculture and incomes, inadequately diverse agricultural production and diets, dependence on imported foods and their price fluctuations, climate change risks and insufficient availability of nutritious foods. Diets of families are primarily comprised of high-energy, nutrient-poor foods,  such as grains, oils, sugars, roots and tubers (potatoes), which are available across the country and often fed to young children. </p>
      </div>
    </>
  ) 
}

export default Availability
