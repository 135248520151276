// Import Components
import PolicyMenuLink from "./PolicyMenuLink";

// Component
const PolicyMenu = ({ active }) => {
  return (
    <div className="flex flex-col bg-white border border-main_blue p-4">
      { active != 'eeca' ? 
        <PolicyMenuLink
            title={'ECAR'}
            linkTo={'/policy-briefs'}
        />
        : <></>
      }
      { active != 'armenia' ? 
        <PolicyMenuLink
            title={'Armenia'}
            linkTo={'/policy-briefs-armenia'}
        />
        : <></>
      }
      { active != 'bosnia' ? 
        <PolicyMenuLink
            title={'Bosnia & Herzegovina'}
            linkTo={'/policy-briefs-bosnia-herzegovina'}
        />
        : <></>
      }
      { active != 'georgia' ? 
        <PolicyMenuLink
            title={'Georgia'}
            linkTo={'/policy-briefs-georgia'}
        />
        : <></>
      }
      { active != 'kazakhstan' ? 
        <PolicyMenuLink
            title={'Kazakhstan'}
            linkTo={'/policy-briefs-kazakhstan'}
        />
        : <></>
      }
      { active != 'serbia' ? 
        <PolicyMenuLink
            title={'Serbia'}
            linkTo={'/policy-briefs-serbia'}
        />
        : <></>
      }
      { active != 'tajikistan' ? 
        <PolicyMenuLink
            title={'Tajikistan'}
            linkTo={'/policy-briefs-tajikistan'}
        />
        : <></>
      }
    </div>
  ) 
}

export default PolicyMenu
