import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
// IMPORT COMPONENTS
import NavItem from './NavItem'
import NavItemLink from './NavItemLink'
import NavSubItem from './NavSubItem'
// COMPONENT
const NavBar = ({ showNav, setShowNav }) => {
    
  // CONSTANTS
  const location = useLocation();
  const mainItem = location.pathname;
  const secItem = location.hash;

  console.log(mainItem);
  console.log(secItem);

  // RETURNING
  return (
    <div className={`
      absolute min-w-460 xs:min-w-0 top-0 xs:w-full right-0 p-2 z-10`
    }> 
      <nav className={`
        w-full
        flex flex-col bg-sec_blue
        justify-center items-start
        py-3 shadow`
      } role="navigation" aria-label="main navigation">
          <NavItem
              title={'EXECUTIVE SUMMARY'}
              navActive={mainItem === '/executive-sumMary' ? true : false}>
              <NavSubItem
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Introduction'}
                  linkTo={'/executive-summary#introduction'}
                  navSubActive={secItem === '#introduction' ? true : false}
                  hashValue={'#introduction'}
              />
              <NavSubItem
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Objectives'}
                  linkTo={'/executive-summary#objectives'}
                  navSubActive={secItem === '#objectives' ? true : false}
                  hashValue={'#objectives'}
              />
              <NavSubItem
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Methods'}
                  linkTo={'/executive-summary#methods'}
                  navSubActive={secItem === '#methods' ? true : false}
                  hashValue={'#methods'}
              />
          </NavItem>
          <NavItem
              title={'FINDINGS'}
              navActive={mainItem === '/findings' ? true : false}>
              <NavSubItem 
                  setShowNav ={ setShowNav }
                  title={'Status of key complementary feeding indicators'}
                  linkTo={'/findings#status-of-key-complementary-feeding-indicators'}
                  navSubActive={secItem === '#status-of-key-complementary-feeding-indicators' ? true : false}
                  hashValue={'#status-of-key-complementary-feeding-indicators'}
              />
          </NavItem>
          <NavItem
              title={'DETERMINANTS AND DRIVERS OF ADEQUATE DIETS'}
              navActive={mainItem === '/determinants-and-drivers-of-adequate-diets' ? true : false}>
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Availability'}
                  linkTo={'/determinants-and-drivers-of-adequate-diets#availability'}
                  navSubActive={secItem === '#availability' ? true : false}
                  hashValue={'#availability'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Access '}
                  linkTo={'/determinants-and-drivers-of-adequate-diets#access'}
                  navSubActive={secItem === '#access' ? true : false}
                  hashValue={'#access'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Affordability'}
                  linkTo={'/determinants-and-drivers-of-adequate-diets#affordability'}
                  navSubActive={secItem === '#affordability' ? true : false}
                  hashValue={'#affordability'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Desirability and convenience'}
                  linkTo={'/determinants-and-drivers-of-adequate-diets#desirability-and-convenience'}
                  navSubActive={secItem === '#desirability-and-convenience' ? true : false}
                  hashValue={'#desirability-and-convenience'}
              />
          </NavItem>
          <NavItem
              title={'ADEQUATE SERVICES'}
              navActive={mainItem === '/adequate-services' ? true : false}>
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Availability, affordability, quality and use of health and nutrition services'}
                  linkTo={'/adequate-services#availability-affordability-quality-and-use-of-health-and-nutrition-services'}
                  navSubActive={secItem === '#availability-affordability-quality-and-use-of-health-and-nutrition-services' ? true : false}
                  hashValue={'#availability-affordability-quality-and-use-of-health-and-nutrition-services'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Availability, quality and coverage of social protection services'}
                  linkTo={'/adequate-services#availability-quality-and-coverage-of-social-protection-services'}
                  navSubActive={secItem === '#availability-quality-and-coverage-of-social-protection-services' ? true : false}
                  hashValue={'#availability-quality-and-coverage-of-social-protection-services'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Availability, quality, affordability and use of water and sanitation services'}
                  linkTo={'/adequate-services#availability-quality-affordability-and-use-of-water-and-sanitation-services'}
                  navSubActive={secItem === '#availability-quality-affordability-and-use-of-water-and-sanitation-services' ? true : false}
                  hashValue={'#availability-quality-affordability-and-use-of-water-and-sanitation-services'}
              />
          </NavItem>
          <NavItem
              title={'ADEQUATE PRACTICES'}
              navActive={mainItem === '/adequate-practices' ? true : false}>
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Caregiver knowledge'}
                  linkTo={'/adequate-practices#caregiver-knowledge'}
                  navSubActive={secItem === '#caregiver-knowledge' ? true : false}
                  hashValue={'#caregiver-knowledge'}
              />
              <NavSubItem 
                  showNav={ showNav }
                  setShowNav={ setShowNav }
                  title={'Social norms'}
                  linkTo={'/adequate-practices#social-norms'}
                  navSubActive={secItem === '#social-norms' ? true : false}
                  hashValue={'#social-norms'}
              />
          </NavItem>
          <NavItemLink
            setShowNav ={ setShowNav }
            title={'ENABLING ENVIRONMENT'}
            navActive={secItem === 'enabling-environment' ? true : false}
            linkTo={'/enabling-environment'}
          />
          <NavItemLink
            setShowNav ={ setShowNav }
            title={'KEY RECOMMENDATIONS'}
            navActive={secItem === 'key-recommendations' ? true : false}
            linkTo={'/key-recommendations'}
          />
          <NavItemLink 
            setShowNav ={ setShowNav }
            title={'POLICY BRIEFS'}
            navActive={secItem === 'policy-briefs' ? true : false}
            linkTo={'/policy-briefs'}
          />
      </nav> 
  
    </div>
  )
}
// PROPTYPES
NavBar.propTypes = {
  showNav: PropTypes.bool.isRequired,
}
// EXPORT
export default NavBar
