import { useState } from 'react'
// Import Components
import PageTitle from '../../components/PageTitle'
// Component
const Availability3  = () => {

  const pageTitle = 'Availability, quality, affordability and use of water and sanitation services'

  return (
    <> 
      <PageTitle title = { pageTitle } />
      <div className="article_content col2">
        <p>In the ECAR region, WASH is not viewed as a  barrier to complementary feeding, according to interviews with stakeholders, Therefore, WASH  and nutrition programming have not been prioritized nor focused upon as an issue in the ECAR focus countries.</p>
        <p>{'In Central Asia, some data indicate that water source and hygiene are issues.  In a cross-sectional survey (n=2358) conducted in  Kazakhstan, Central Uzbekistan and Kyrgyzstan showed that the odds of being stunted for children < 3 years of age were nearly 1.4 times higher in households without piped water  compared to households with piped water.'}</p>
      </div>
    </>
  )
}

export default Availability3
