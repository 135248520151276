// Import Components
import PageTitle from '../../components/PageTitle'
// Component
const Objectives = () => {

  const pageTitle = 'Objectives'

  return (
    <> 
      <PageTitle title={ pageTitle } /> 
      <div className="article_content col2">
        <p>This landscape analyses examines policies and programs, barriers, drivers and enabling factors  on young children’s diets in ECAR region.  The analyses provides key recommendations and actions to improve complementary feeding for the region.</p>
        <div className="noColumnBreak">
          <p className="halfPad">The objectives of this landscape analyses are: </p>
          <ul className="bullets pb-0">
            <li>To provide an in-depth understanding of facilitating factors and barriers to complementary feeding in UNICEF’s Eastern Europe and Central Asia Region (UNICEF ECAR)</li>
            <li>To generate policy recommendations for improving young children’ diets</li>
          </ul>
        </div>
      </div>
    </>
  ) 
}

export default Objectives
