const Table05 = () => {
  return (  
    <table className="table-scroll-2 tableRightBorder purple text-left border border-purple xs:text-xs">
      <thead>
        <tr className="text-white bg-purple">
            <th className="text-white bg-purple">
              <p className="font-bold">Taboo food,</p>
              <p>infants and young children</p>
            </th>
            <th><p className="font-bold">Perceived harm(s</p></th>
            <th className="text-white bg-purple">
              <p className="font-bold">Family member who holds belief</p>
              <p>Married women (MW), Mother-in-law (MIL), Fathers</p>
            </th>
        </tr>
      </thead>
      <tbody>

        <tr className="bg-purple_pale">
          <td className="bg-purple_pale">
            <p className="font-bold">Egg</p>
          </td>
          <td>
            <ul className="bullets">
              <li>Gastric upset</li>
              <li>Delayed language acquisition (late speech)</li>
              <li>High-calorie foods (fathers only)</li>
            </ul>
          </td>
          <td>
            <p className="font-bold">MW, MIL, Fathers</p>
            <ul className="bullets">
              <li>"Babies who didn’t start talking, they shouldn’t eat eggs, because it will influence, they will start speaking very late. (MIL)</li>
              <li>“if [children] eat eggs they have the problems with their stomach."(MIL)</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td className="bg-white">
            <p className="font-bold">Oily, and carbohydrate-rich staple foods</p>
            <ul className="bullets">
              <li>Fatir (a type of bread)</li>
              <li>Sambusa (meat/vegetable- filled pastry)</li>
              <li>Osh (rice dish)</li>
              <li>Mantou (meat/vegetable-filled dumpling)</li>
              <li>Goat and lamb (“oily”)</li>
            </ul>
          </td>
          <td>
            <ul className="bullets">
              <li>Too hard or heavy</li>
              <li>Difficult to digest</li>
              <li>Exclude from diet until child is greater than 2 years of age</li>
            </ul>
          </td>
          <td>
            <p className="font-bold">MW, MIL</p>
            <ul className="bullets">
              <li>“starting at two, three years, but in very small amounts starting from two years” (MW)</li>
              <li>“for some children, these practices continue past the age of 2 years due to taste preference or household dietary practice” (MW)</li>
            </ul>
          </td>
        </tr>

        <tr className="bg-purple_pale">
          <td className="bg-purple_pale">
            <p className="font-bold">Some fruits and vegetables</p>
            <ul className="bullets">
              <li>Tomato</li>
              <li>Cucumber</li>
              <li>Watermelon</li>
              <li>Grapes</li>
            </ul>
          </td>
          <td>
            <ul className="bullets">
              <li>Gastric upset- Fruits and vegetables grown inside greenhouses (MW) or with contaminated irrigation water (MIL)</li>
              <li>Food-borne illness</li>
            </ul>
          </td>
          <td>
            <p className="font-bold">MW, MIL</p>
            <ul className="bullets">
              <li>“We don’t give them [children] tomato, cucumber, watermelon, and grapes, because of diarrhea”</li>
              <li>“Some people even died when they ate watermelons and melon…. from botulism”</li>
              <li>”…we live in a village where kids might go to the garden and pick a cucumber and eat it without even washing off those chemicals. We should wash and peel then feed those types of things.”</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td className="bg-white">
            <ul className="bullets">
              <li>Plov (rice)</li>
              <li>Meat</li>
            </ul>
          </td>
          <td>
            <ul className="bullets">
              <li>Gastric upset</li>
              <li>Too early to eat, need teeth</li>
            </ul>
          </td>
          <td>
            <p className="font-bold">MW</p>
            <p>“I don't give plov (pilaf) to my child. How can you feed a one year old with plov? It's still early. It depends on whether the child has teeth or not. We can give carrots from plov if not the rice from plov. Some children have teeth before they are one year old”</p>
          </td>
        </tr>
        
      </tbody>
    </table>
  ) 
}
export default Table05
